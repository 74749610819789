import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function Twitter({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g
        id="Library"
        stroke="none"
        strokeWidth="1"
        fill={isCssColor ? 'currentColor' : color}
        fillRule="evenodd"
      >
        <g id="library-/icon-/twitter-/default">
          <g id="Group" transform="translate(1.349796, 3.775616)">
            <path
              d="M20.723146,2.81580414 C20.0583858,3.14028124 19.3224712,3.39423176 18.5865567,3.53811636 C17.8506422,2.31076345 16.6250518,1.44312202 15.224377,1.22729513 C14.9795321,1.19061611 14.7347011,1.15534561 14.4884625,1.15534561 C12.1070353,1.15534561 10.1113539,2.92443268 9.76118521,5.34547875 C9.72562207,5.59800633 9.6914246,5.88721291 9.6914246,6.13973326 C6.95982869,5.0929584 4.71937938,2.99796406 3.45807176,0.252527573 C3.38831115,0.108628534 3.24742428,0.0352705053 3.10790307,0 C2.96838186,0 2.82749499,0.0719495195 2.72215724,0.180578053 C1.91648912,1.44462443 1.70722901,2.96119115 2.1271506,4.3707112 C2.05739,4.3707112 1.98762939,4.33403218 1.95206626,4.3707112 C1.77698191,4.40739021 1.67165888,4.51461024 1.6374614,4.69518829 C1.25171557,6.21175501 1.6374614,7.83695749 2.54845956,9.02906157 C2.44313583,9.02906157 2.37337522,9.06574058 2.30361461,9.1376901 C2.1640934,9.24631863 2.12853027,9.42689669 2.1640934,9.5707885 C2.54983923,11.1240487 3.63452176,12.3880951 5.03519652,12.9298292 C3.56476816,13.4715634 2.02318552,13.6888349 0.447286699,13.5801991 C0.272202355,13.5801991 0.0971180099,13.6888277 0.027364407,13.8694057 C-0.042389196,14.0499838 0.027364407,14.2305618 0.16688562,14.3744464 C1.74264437,15.6017993 3.59881856,16.3974985 5.55878275,16.7219611 C6.18936653,16.8305896 6.78437316,16.8658601 7.41495694,16.8658601 C13.7180336,16.8658601 17.3431132,13.9893784 19.0062409,7.80011957 C19.1115646,7.51091298 19.1813252,7.18644311 19.2168884,6.8972293 C19.2168884,6.82527978 19.2524515,6.75333026 19.2524515,6.67997223 C19.2880146,6.5713437 19.2880146,6.46271517 19.3222121,6.35549514 C20.1278802,5.48929834 20.7926405,4.51302115 21.283717,3.42955283 C21.3534776,3.28565379 21.3192802,3.06839673 21.2139564,2.95976097 C21.1200319,2.84031701 20.9564284,2.79233141 20.723146,2.81580414 Z"
              id="Fill-3"
              stroke="#000000"
              strokeWidth="0.75"
            />
            <circle
              id="Oval"
              fill="#000000"
              cx="15.2848854"
              cy="4.76936481"
              r="1"
            />
          </g>
        </g>
      </g>
    </Icon>
  );
}

Twitter.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

Twitter.defaultProps = {
  className: undefined,
  color: css.colorBlack,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 18,
};

export default Twitter;
