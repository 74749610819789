import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function AffinityFire({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g fill={isCssColor ? 'currentColor' : color} fillRule="evenodd">
        <path d="M12.024 18.364a6.051 6.051 0 0 1-5.58-8.372l2.723 2.174 2.894-4.951 3.182 4.999 2.37-2.2a6.05 6.05 0 0 1-5.589 8.35m6.705-10.566l-.668-.988-2.411 2.24-3.663-5.755-3.392 5.802L5.89 6.934l-.616.933a8.052 8.052 0 0 0-1.335 4.453c0 4.458 3.627 8.086 8.086 8.086 4.459 0 8.086-3.628 8.086-8.086 0-1.62-.478-3.184-1.381-4.522" />
        <path d="M12.596 12.566l-.571-.386-.571.385c-1.793 1.207-2.412 3.194-2.5 3.506h2.176c.171-.376.456-.884.896-1.335.338.32.752.782 1.031 1.335h2.184c-.594-2.097-2.561-3.448-2.645-3.505" />
      </g>
    </Icon>
  );
}

AffinityFire.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

AffinityFire.defaultProps = {
  className: undefined,
  color: css.colorBlack,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 18,
};

export default AffinityFire;
