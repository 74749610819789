import React, { Fragment, PureComponent } from 'react';
import Link from 'next/link';
import * as pages from 'constants/pages';
import css from './Header.css';

class Header extends PureComponent {
  render() {
    return (
      <Fragment>
        <div className={css.header} data-cy="header">
          <div className={css.logoBar}>
            <Link href={pages.HOME}>
              <a>
                <img
                  className={css.logoImg}
                  src="/static/img/logo/mark.svg"
                  alt="Cheeze Wizards"
                />
              </a>
            </Link>
          </div>
        </div>
      </Fragment>
    );
  }
}

export default Header;
