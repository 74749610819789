import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function ShareLink({ className, color, size, ...props }) {
  return (
    <Icon className={className} viewBox="0 0 24 24" size={size} {...props}>
      <g
        id="Library"
        stroke="none"
        strokeWidth="1.5"
        fill="none"
        fillRule="evenodd"
        strokeLinejoin="round"
      >
        <g
          id="library-/icon-/fb-/default"
          fillRule="nonzero"
          stroke="#000000"
          strokeWidth="1.5"
        >
          <path
            d="M13.7415617,22 L13.7415617,12.877031 L16.8036975,12.877031 L17.2622089,9.32164812 L13.7415617,9.32164812 L13.7415617,7.05176078 C13.7415617,6.02239539 14.0274001,5.32091684 15.503485,5.32091684 L17.3861606,5.32009415 L17.3861606,2.14013117 C17.060559,2.09680294 15.9429832,2 14.6427706,2 C11.9283576,2 10.069997,3.65689344 10.069997,6.69965036 L10.069997,9.32164812 L7,9.32164812 L7,12.877031 L10.069997,12.877031 L10.069997,22 L13.7415617,22 Z"
            id="f"
          />
        </g>
      </g>
    </Icon>
  );
}

ShareLink.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
};

ShareLink.defaultProps = {
  className: undefined,
  color: css.colorBlack,
  size: 18,
};

export default ShareLink;
