import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function Info({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <circle
          stroke={isCssColor ? 'currentColor' : color}
          strokeWidth=".75"
          cx="12"
          cy="12"
          r="10.625"
          vectorEffect="non-scaling-stroke"
        />
        <path
          stroke={isCssColor ? 'currentColor' : color}
          strokeLinecap="square"
          d="M12 9.5v9.018"
          vectorEffect="non-scaling-stroke"
        />
        <circle
          fill={isCssColor ? 'currentColor' : color}
          cx="12"
          cy="6"
          r="1"
          vectorEffect="non-scaling-stroke"
        />
      </g>
    </Icon>
  );
}

Info.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

Info.defaultProps = {
  className: undefined,
  color: css.colorBlack,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 18,
};

export default Info;
