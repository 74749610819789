import React from 'react';
import Icons from 'components/Icons';
import css from './Footer.css';

function Footer() {
  return (
    <footer className={css.footer}>
      <span className={css.link}>
        Made with{' '}
        <Icons.CheeseSlice isCssColor className={css.icon} size={18} />
      </span>
    </footer>
  );
}

export default Footer;
