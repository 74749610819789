import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function Share({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g
        stroke={isCssColor ? 'currentColor' : color}
        strokeWidth=".75"
        fill="none"
        fillRule="evenodd"
      >
        <rect x="13.844" y="3.148" width="5.98" height="5.98" rx="2.99" />
        <rect x="13.844" y="14.948" width="5.98" height="5.98" rx="2.99" />
        <rect x="3.944" y="9.283" width="5.98" height="5.98" rx="2.99" />
        <path
          d="M9.509 10.546l4.703-2.787M9.648 13.823l4.564 2.693"
          strokeLinecap="square"
        />
      </g>
    </Icon>
  );
}

Share.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

Share.defaultProps = {
  className: undefined,
  color: css.colorGrey900,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};

export default Share;
