import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function DuelActiveColor({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g>
        <path fill="#FFE96E" d="M5 2h14v19.25L12 17l-7 4.25z" />
        <path
          d="M20 .571l1.414 1.415L20 3.4l-.625-.626V22.25a.375.375 0 0 1-.5.354l-.07-.033L12 18.439 5.195 22.57a.375.375 0 0 1-.57-.321V2.524L3.75 3.4 2.336 1.986 3.75.57l1.09 1.09A.374.374 0 0 1 5 1.625h13.947L20 .57zm-1.625 2.054H5.625v17.514L12 16.27l6.375 3.87V2.625zm-3.212 4.306c.646 0 1.095.147 1.413.364l-.01 1.242-.242.049c-.131-.345-.493-.601-.997-.601-.417 0-.756.197-.756.492 0 .21.086.343.398.522l1.015.552c.636.345 1.096.798 1.096 1.626 0 1.133-1.117 1.754-2.104 1.754-.778 0-1.435-.217-1.885-.71l-.01-1.409.273-.01c.22.641.811.946 1.436.946.416 0 .723-.206.723-.482 0-.296-.186-.473-.613-.68l-.69-.375c-.79-.423-1.097-.906-1.097-1.605 0-.877.658-1.675 2.05-1.675zm-6.357.107l-.057.223h-.067c-.24.025-.373.155-.285.384l.037.08 1.183 2.25 1.183-2.25c.13-.25.034-.404-.181-.452l-.076-.012h-.067l-.057-.223.206.003.338.015h1.44c.086 0 .215-.01.339-.015l.12-.003h.085l-.047.223h-.077c-.237.024-.294.086-.417.315l-.05.094-2.739 5.085L6.87 7.67c-.136-.273-.18-.362-.384-.398l-.083-.011h-.067l-.058-.223.206.003.338.015h1.441c.086 0 .215-.01.338-.015l.12-.003h.086zM3.75 1.632l-.354.354.354.353.354-.353-.354-.354zm16.25 0l-.354.354.354.353.354-.353L20 1.632z"
          fill="#000"
          fillRule="nonzero"
        />
      </g>
    </Icon>
  );
}

DuelActiveColor.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

DuelActiveColor.defaultProps = {
  className: undefined,
  color: css.colorGrey900,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};

export default DuelActiveColor;
