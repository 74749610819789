import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

function TowerColor({ className, isFlipX, isFlipY, size, ...props }) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#AEFFFF"
          d="M11.3 22.909l9.02-2.897-2.248-15.658-7.74-.41-4.814 1.068-1.317 15.96z"
        />
        <path fill="#CFF" d="M11.3 22.909l9.02-2.897-2.248-15.658-7.74-.41z" />
        <g transform="translate(4.201 1.1)">
          <path
            d="M7.09 21.418l8.613-2.766-2.16-15.04-7.382-.39-4.493.996L.399 19.592l6.692 1.826z"
            stroke="#000"
            strokeWidth=".75"
          />
          <path
            d="M6.328 3.52l.663 17.7"
            stroke="#000"
            strokeWidth=".75"
            strokeLinecap="square"
          />
          <path
            d="M2.646 12.046l-.065 1.123m-.113 2.079l-.065 1.123m.419-7.528l-.065 1.124m1.98 2.206v1.123m-.009 2.294v1.123m0-7.807v1.123M8.38 8.843l.098 1.12m.486 5.48l.097 1.119m-.448-4.386l.098 1.119m1.691-4.711l.097 1.119m.605 5.209l.097 1.119m-.473-4.185l.098 1.119m1.495-4.608l.098 1.12m.63 4.903l.098 1.12m-.466-4.042l.098 1.12"
            stroke="#000"
            strokeWidth=".75"
            strokeLinecap="round"
          />
          <path
            d="M3.303 3.776c.088-1.016.35-2.436 1.12-2.713 1.223-.441 3.815-.06 3.815-.06s2.828.155 2.978 1.299c.061.46.108.855.143 1.172"
            stroke="#000"
            strokeWidth=".75"
          />
          <ellipse
            fill="#000"
            fillRule="nonzero"
            cx="9.953"
            cy="1.314"
            rx="1"
            ry="1"
          />
          <path
            fill="#000"
            fillRule="nonzero"
            d="M6.262 0l3.851.722-.095 1.203L6.262 1.6"
          />
          <ellipse
            fill="#000"
            fillRule="nonzero"
            cx="6.262"
            cy=".802"
            rx="1"
            ry="1"
          />
        </g>
        <path
          d="M19.744 10.75v.203l-.023.024-.024-.024v-.203h.047zm-.022-1.61a2.626 2.626 0 0 1 2.246-2.242 2.626 2.626 0 0 1-2.248-2.25 2.626 2.626 0 0 1-2.246 2.241 2.626 2.626 0 0 1 2.248 2.25zm3.867-2.266h.201l.025.024-.025.025h-.201v-.049zM19.697 3.25v-.203l.024-.024.023.024v.203h-.047zM16.09 6.913h-.202l-.024-.024.024-.024h.202v.048z"
          stroke="#000"
          strokeWidth=".5"
          fill="#FFF"
        />
      </g>
    </Icon>
  );
}

TowerColor.propTypes = {
  className: PropTypes.string,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

TowerColor.defaultProps = {
  className: undefined,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};

export default TowerColor;
