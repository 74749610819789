import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

function CauldronColor({ className, isFlipX, isFlipY, size, ...props }) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M21.419 11.436c-1.366 4.296-4.741 7.107-9.887 7.107-4.53 0-7.468-3.06-9.226-7.107 0 0-2.094-6.245 1.56-7.494.056-.02 4.784-.447 8.096-.447 3.312 0 6.06-.467 8.592.757 2.532 1.224.469 7.184.865 7.184z"
          fill="#FFE9F1"
          fillRule="nonzero"
        />
        <path
          d="M19.426 14.726l.367.589a1.492 1.492 0 0 0 2.123.488c.721-.53.917-1.523.451-2.288l-1.097-2.682M2.647 11.686L1.53 14.198c-.38.808-.222 1.692.554 2.134.775.441 1.825.023 2.211-.669l.43-.652"
          fill="#FFC6DB"
        />
        <path
          d="M6.245 13.435l-1.592 5.308a2.054 2.054 0 0 0 1.167 2.553 1.853 1.853 0 0 0 2.384-1.253l2.261-4.58M13.7 14.46l1.699 5.254A1.858 1.858 0 0 0 17.772 21a2.049 2.049 0 0 0 1.194-2.537l-.786-5.117"
          fill="#FFE9F1"
          fillRule="nonzero"
        />
        <g transform="translate(.68 10.115)">
          <path
            d="M18.746 4.611l.367.59a1.492 1.492 0 0 0 2.123.488c.721-.53.917-1.524.451-2.288L20.59.719M1.966 1.571L.85 4.083c-.38.808-.221 1.692.554 2.134.776.442 1.826.023 2.212-.669.19-.29.334-.507.43-.652M5.565 3.32L3.972 8.628a2.054 2.054 0 0 0 1.168 2.553A1.853 1.853 0 0 0 7.524 9.93l2.26-4.581M13.273 5.112L14.719 9.6a1.858 1.858 0 0 0 2.372 1.284 2.049 2.049 0 0 0 1.195-2.537L17.5 3.23"
            stroke="#000"
            strokeWidth=".75"
          />
          <ellipse
            fill="#000"
            fillRule="nonzero"
            transform="rotate(-67.75 5.578 9.888)"
            cx="5.578"
            cy="9.888"
            rx="1"
            ry="1"
          />
          <ellipse
            fill="#000"
            fillRule="nonzero"
            transform="rotate(-22.25 16.544 9.362)"
            cx="16.544"
            cy="9.362"
            rx="1"
            ry="1"
          />
          <path
            d="M8.65 8.01c1.135.626 4.854.493 5.497 0"
            stroke="#000"
            strokeWidth=".75"
          />
        </g>
        <path
          d="M15.906 9.75v.203l-.024.024-.023-.024V9.75h.047zm-.023-1.61a2.626 2.626 0 0 1 2.246-2.242 2.626 2.626 0 0 1-2.247-2.25 2.626 2.626 0 0 1-2.246 2.241 2.626 2.626 0 0 1 2.247 2.25zm3.867-2.266h.202l.024.024-.024.025h-.202v-.049zM15.859 2.25v-.203l.023-.024.024.024v.203h-.047zM12.25 5.913h-.202l-.024-.024.024-.024h.202v.048z"
          stroke="#000"
          strokeWidth=".5"
          fill="#FFF"
        />
        <path
          d="M5.572 15.615C3.402 13.93-.114 7.864 3.402 4.448c1.945-1.889 5.93-.831 8.437-.792 2.507.04 7.227-1.487 9.184 1.072 2.324 3.04-1.22 9.973-2.493 10.887"
          stroke="#000"
          strokeWidth=".75"
        />
      </g>
    </Icon>
  );
}

CauldronColor.propTypes = {
  className: PropTypes.string,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

CauldronColor.defaultProps = {
  className: undefined,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};

export default CauldronColor;
