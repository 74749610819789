import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function Book({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g
        stroke={isCssColor ? 'currentColor' : color}
        strokeWidth=".75"
        fill="none"
        fillRule="evenodd"
      >
        <path d="M13.303 19.505l-.036-13.543s5.751-2.64 9.085.171l.045 14.07s-3.874-2.795-9.094-.698zM3.327 6.69l-1.991.374 1.399 13.518 10.532-.753c-3.16-2.442-9.325-.752-9.325-.752L3.271 4.995s6.85-3.564 9.996.967" />
        <path d="M13.281 19.83l7.77.936.125-1.166" />
      </g>
    </Icon>
  );
}

Book.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

Book.defaultProps = {
  className: undefined,
  color: css.colorGrey900,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};

export default Book;
