import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function Sent({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g fill="#000" fillRule="nonzero">
        <path d="M23.99 3.457l-.023.07-3.957 8.85-.245.71a1.484 1.484 0 0 0-1.8.736c-.318.609-.16 1.253.519 1.68l.142.082-.266.482-1.247 2.794a.385.385 0 0 1-.5.195l-.068-.037-4.151-2.828-4.332 4.282a.385.385 0 0 1-.633-.146l-.017-.073-.988-8H.382c-.397 0-.516-.506-.206-.692l.073-.034L5.89 9.463l.001.003.316-.131c.138.318 1.052.424 1.864.105.632-.248 1.008-.67.96-1.042l-.02-.085.13-.04 14.342-5.248c.292-.107.572.152.506.432zM9.481 14.208l-1.425 5.036.02.151 3.68-3.638-2.275-1.549zM22.81 4.249l-3.595 2.58c.725.901-.364 2.1-1.943 1.393L9.85 13.545l6.75 4.598 1.046-2.343c-.653-.64-.778-1.52-.36-2.32a2.26 2.26 0 0 1 1.932-1.22l3.592-8.011zM13.75 13a.75.75 0 1 1 0 1.5.75.75 0 0 1 0-1.5zm8.298-8.65L9.746 8.853c-.17.544-.696 1.01-1.39 1.283-.79.31-1.677.333-2.267.056L2.5 11.504h4.178L22.048 4.35z" />
      </g>
    </Icon>
  );
}

Sent.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

Sent.defaultProps = {
  className: undefined,
  color: css.colorGrey900,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};

export default Sent;
