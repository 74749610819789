import React, { PureComponent } from 'react';
import Header from './Header';

class HeaderContainer extends PureComponent {
  render() {
    return <Header />;
  }
}

export default HeaderContainer;
