import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function Received({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g fill="000" fillRule="nonzero">
        <path d="M10.703.25c.229 0 .414.186.414.414v2.494l7.446 1.314.062.01.283.052-.003.012a3.678 3.678 0 0 1 2.711 3.214l.015.273v7.149l-7.536.926-.006.022-.055-.015-.014.002-.001-.006-2.655-.718v6.692a1.243 1.243 0 0 1-2.487 0V14.72l-4.519-1.222V15h-.452v-1.513l-.004-.112-1.667-.45v-1.703a2.376 2.376 0 0 0-.523-.1l-.162-.007H.25v-.452h1.282c.245 0 .48-.037.703-.106V7.165l.005-.222C2.345 4.775 3.863 2.796 6.2 2.57L4.266 1.044c-.3-.237-.093-.72.286-.665l5.735.823V.664c0-.228.186-.414.415-.414zM6.077 3.433c-1.621.26-2.918 1.8-3.008 3.538l-.005.194v2.937c.475-.401.79-.986.836-1.644l.006-.162V7h.453v1.305a2.375 2.375 0 0 0 2.211 2.362l.162.006H8.25v.451H6.732a2.379 2.379 0 0 0-2.228 1.555l9.518 2.573.025-6.918a3.925 3.925 0 0 1 2.077-3.45L11.117 4v5.94a.414.414 0 0 1-.829 0V5.802L7.152 3.322a8.41 8.41 0 0 0-1.075.111zm14.125 8.986l-4.307.455v1.109l4.307-.52V12.42zm-17.138-.743v.614l.604.163a2.381 2.381 0 0 0-.604-.777zM4.13 9.52c-.234.493-.606.91-1.065 1.2v.338c.4.253.733.601.967 1.013l.102.197.103-.196c.292-.51.736-.921 1.271-1.174A2.886 2.886 0 0 1 4.13 9.52zM21.054 1v.653A1.187 1.187 0 0 0 22.12 2.83l.121.006H23v.226h-.759a1.188 1.188 0 0 0-1.18 1.063l-.007.12V5h-.226v-.757c0-.607-.458-1.108-1.05-1.177l-.128-.008H19v-.226h.641a1.188 1.188 0 0 0 1.18-1.063l.007-.12V1h.226zm-15.23.32l4.464 3.526V1.96l-4.463-.64zM20.94 2.721a1.697 1.697 0 0 1-.223.223l.119.108.108.12.107-.118.117-.106a1.697 1.697 0 0 1-.228-.227z" />
      </g>
    </Icon>
  );
}

Received.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

Received.defaultProps = {
  className: undefined,
  color: css.colorGrey900,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};

export default Received;
