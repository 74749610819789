import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function MagicHat({ className, color, size, ...props }) {
  return (
    <Icon className={className} viewBox="0 0 24 24" size={size} {...props}>
      <g
        id="Library"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="library-/icon-/wizard-/hover">
          <path
            d="M3.14497482,19.3650606 L11.1018134,2.93436853 C11.3425265,2.43730147 11.9406155,2.2294855 12.4376825,2.47019858 C12.6397339,2.56804537 12.8029838,2.73103412 12.9011538,2.93292867 L20.8911807,19.3650606 L3.14497482,19.3650606 Z"
            id="Path"
          />
          <ellipse
            id="Oval"
            stroke="#000000"
            strokeWidth="0.75"
            fill="#000000"
            cx="12"
            cy="19.5912686"
            rx="8.88081753"
            ry="2.14931387"
          />
          <path
            d="M3.35611092,18.9868991 L20.6456409,18.8977156 L12.4196151,2.74348544 C12.3748948,2.65566396 12.3022522,2.58411207 12.2123114,2.53972376 C11.9804345,2.425286 11.6985265,2.51803895 11.5838939,2.74433669 L3.35611092,18.9868991 Z"
            id="Path"
            stroke="#000000"
            strokeWidth="0.75"
          />
          <path
            d="M18.9057191,8.75 L18.9057191,8.95300113 L18.8822197,8.97650057 L18.8587203,8.95300115 L18.8587203,8.75 L18.9057191,8.75 Z M18.8828333,7.13918192 C19.0413355,5.97675403 19.9636024,5.05682456 21.1290374,4.89838708 C19.9607952,4.73968701 19.0366527,3.81536878 18.8816365,2.64828594 C18.723234,3.81072915 17.8010541,4.7307136 16.6356724,4.88926672 C17.8049015,5.04825241 18.7280254,5.97333396 18.8828333,7.13918192 Z M22.75,4.8742236 L22.9517222,4.8742236 L22.9758611,4.89836251 L22.9517222,4.9225014 L22.75,4.92250134 L22.75,4.8742236 Z M18.8587203,1.25 L18.8587203,1.04699887 L18.8822197,1.02349943 L18.9057191,1.04699885 L18.9057191,1.25 L18.8587203,1.25 Z M15.25,4.91346697 L15.0482778,4.91346697 L15.0241389,4.88932806 L15.0482774,4.86518958 L15.25,4.86519132 L15.25,4.91346697 Z"
            id="Path"
            stroke="#000000"
            strokeWidth="0.5"
            fill="#FFFFFF"
          />
          <path
            d="M4.44908841,11.6608513 L4.44908841,11.6608456 L4.44908841,11.6608513 Z M4.42558897,12.4174803 L4.19908841,12.4174803 L4.19908841,12.1674803 L4.42558897,12.1674803 L4.42558897,12.4174803 Z M4.31341641,11.0514401 C4.45587312,10.7514404 4.69950309,10.5086022 5.00038835,10.3666989 C4.69805019,10.2240927 4.45347723,9.97947906 4.31130917,9.6772643 C4.16886907,9.97728999 3.92524192,10.2201541 3.62435145,10.3620775 C3.92682047,10.5047325 4.17131342,10.7494033 4.31341641,11.0514401 Z M6.37122884,10.253731 L6.37122884,10.4795921 L6.12122884,10.4795921 L6.12122884,10.253731 L6.37122884,10.253731 Z M4.19908841,8.4174803 L4.42558897,8.4174803 L4.42558897,8.6674803 L4.19908841,8.6674803 L4.19908841,8.4174803 Z M2.37122884,10.4750749 L2.37122884,10.2492138 L2.62122884,10.2492138 L2.62122884,10.4750749 L2.37122884,10.4750749 Z"
            id="Path-Copy"
            stroke="#000000"
            strokeWidth="0.5"
            fill="#000000"
          />
        </g>
      </g>
    </Icon>
  );
}

MagicHat.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
};

MagicHat.defaultProps = {
  className: undefined,
  color: css.colorBlack,
  size: 18,
};

export default MagicHat;
