import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function Chat({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <circle
          fill={isCssColor ? 'currentColor' : color}
          cx="7.5"
          cy="10.5"
          r="1"
        />
        <circle
          fill={isCssColor ? 'currentColor' : color}
          cx="12"
          cy="10.5"
          r="1"
        />
        <circle
          fill={isCssColor ? 'currentColor' : color}
          cx="16.5"
          cy="10.5"
          r="1"
        />
        <path
          d="M8.991 21.91c2.48-.6 4.37-1.928 5.693-3.991l.11-.173h1.139a7.692 7.692 0 1 0 0-15.384H8.067a7.692 7.692 0 1 0 0 15.384h1.954l-.023.397c-.085 1.461-.392 2.685-1.007 3.767z"
          stroke={isCssColor ? 'currentColor' : color}
          strokeWidth="0.75"
        />
      </g>
    </Icon>
  );
}

Chat.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

Chat.defaultProps = {
  className: undefined,
  color: css.colorGrey900,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};

export default Chat;
