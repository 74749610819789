import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function Tower({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g transform="translate(4.201 1.1)" fill="none" fillRule="evenodd">
        <path
          stroke={isCssColor ? 'currentColor' : color}
          strokeWidth=".75"
          d="M7.09 21.418l8.613-2.766-2.16-15.04-7.382-.39-4.493.996L.399 19.592l6.692 1.826z"
        />
        <path
          stroke={isCssColor ? 'currentColor' : color}
          strokeWidth=".75"
          strokeLinecap="square"
          d="M6.328 3.52l.663 17.7"
        />
        <path
          stroke={isCssColor ? 'currentColor' : color}
          strokeWidth=".75"
          strokeLinecap="round"
          d="M2.646 12.046l-.065 1.123m-.113 2.079l-.065 1.123m.419-7.528l-.065 1.124m1.98 2.206v1.123m-.009 2.294v1.123m0-7.807v1.123M8.38 8.843l.098 1.12m.486 5.48l.097 1.119m-.448-4.386l.098 1.119m1.691-4.711l.097 1.119m.605 5.209l.097 1.119m-.473-4.185l.098 1.119m1.495-4.608l.098 1.12m.63 4.903l.098 1.12m-.466-4.042l.098 1.12"
        />
        <path
          stroke={isCssColor ? 'currentColor' : color}
          strokeWidth=".75"
          d="M3.303 3.776c.088-1.016.35-2.436 1.12-2.713 1.223-.441 3.815-.06 3.815-.06s2.828.155 2.978 1.299c.061.46.108.855.143 1.172"
        />
        <ellipse
          fill={isCssColor ? 'currentColor' : color}
          fillRule="nonzero"
          cx="9.953"
          cy="1.314"
          rx="1"
          ry="1"
        />
        <path
          fill={isCssColor ? 'currentColor' : color}
          fillRule="nonzero"
          d="M6.262 0l3.851.722-.095 1.203L6.262 1.6"
        />
        <ellipse
          fill={isCssColor ? 'currentColor' : color}
          fillRule="nonzero"
          cx="6.262"
          cy=".802"
          rx="1"
          ry="1"
        />
      </g>
    </Icon>
  );
}

Tower.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

Tower.defaultProps = {
  className: undefined,
  color: css.colorGrey900,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};

export default Tower;
