import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function Link({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g
        stroke={isCssColor ? 'currentColor' : color}
        strokeWidth=".75"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <path d="M12.693 5.976c2.222-2.453-.188.284 3.124-3.222 3.313-3.506 8.377 1.092 5.148 4.607-3.23 3.514-3.93 4.323-5.148 5.358-1.218 1.036-3.552 1.183-5.295-.499" />
        <path d="M11.306 17.122c-2.221 2.453.189-.284-3.124 3.222-3.312 3.506-8.377-1.092-5.147-4.607 3.23-3.515 3.93-4.323 5.147-5.359 1.218-1.035 3.552-1.182 5.296.5" />
      </g>
    </Icon>
  );
}

Link.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

Link.defaultProps = {
  className: undefined,
  color: css.colorGrey900,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};

export default Link;
