import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function ClashWinCosmic({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      {...{
        className,
        isFlipX: !isFlipX,
        viewBox: '0 0 24 24',
        size,
        ...props,
      }}
    >
      <g
        stroke={isCssColor ? 'currentColor' : color}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M14 22.75L3 12 14 1.25" />
        <path d="M21 22.75L10 12 21 1.25" />
      </g>
    </Icon>
  );
}

ClashWinCosmic.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

ClashWinCosmic.defaultProps = {
  className: undefined,
  color: css.colorBlack,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 18,
};

export default ClashWinCosmic;
