import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function AffinityNeutral({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g fill={isCssColor ? 'currentColor' : color} fillRule="evenodd">
        <path d="M3.282 18.037c-.001-.057-.004-.115-.004-.173 0-5.505 3.953-9.985 8.812-9.985 4.86 0 8.813 4.48 8.813 9.985 0 .058-.003.116-.004.173h2.085c0-.057.004-.115.004-.173 0-6.655-4.889-12.07-10.898-12.07-6.008 0-10.897 5.415-10.897 12.07 0 .058.003.116.004.173h2.085z" />
        <path d="M7.037 18.037c-.001-.058-.008-.114-.008-.173 0-3.19 2.27-5.787 5.061-5.787s5.061 2.596 5.061 5.787c0 .059-.006.115-.007.173h2.088c.001-.057.004-.115.004-.173 0-4.34-3.205-7.872-7.146-7.872-3.94 0-7.146 3.532-7.146 7.872 0 .058.003.116.004.173h2.09z" />
        <path d="M10.855 18.037c-.006-.057-.015-.113-.015-.173 0-.84.561-1.522 1.25-1.522.69 0 1.25.683 1.25 1.522 0 .06-.009.116-.014.173h2.091c.003-.057.008-.114.008-.173 0-1.989-1.496-3.608-3.335-3.608s-3.335 1.62-3.335 3.608c0 .059.006.116.009.173h2.09z" />
      </g>
    </Icon>
  );
}

AffinityNeutral.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

AffinityNeutral.defaultProps = {
  className: undefined,
  color: css.colorBlack,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 18,
};

export default AffinityNeutral;
