import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

function BookColor({ className, isFlipX, isFlipY, size, ...props }) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M1.336 7.064l1.99-.374-.055-1.695c3.1-1.007 5.201-1.451 6.303-1.332 1.103.12 2.338.875 3.707 2.266 2.571-.784 4.379-1.144 5.424-1.081 1.045.062 2.275.495 3.692 1.299v13.84l-1.157-.616-.233 1.396-7.74-.938-10.532.753L1.336 7.064z"
          fill="#AC87F8"
        />
        <path
          d="M3.27 4.995c3.1-1.007 5.202-1.451 6.304-1.332 1.103.12 2.338.875 3.707 2.266 2.571-.784 4.379-1.144 5.424-1.081 1.045.062 2.275.495 3.692 1.299v13.84c-1.156-.867-2.387-1.312-3.692-1.334-1.306-.023-3.118.37-5.438 1.176-1.183-.692-2.344-1.084-3.48-1.176-1.138-.092-3.086.05-5.845.424L3.271 4.995z"
          fill="#D8C9FC"
        />
        <path
          d="M13.281 5.929c2.571-.784 4.379-1.144 5.424-1.081 1.045.062 2.275.495 3.692 1.299v13.84c-1.156-.867-2.387-1.312-3.692-1.334-1.306-.023-3.118.37-5.438 1.176l.014-13.9z"
          fill="#EAE1FF"
        />
        <g stroke="#000" strokeWidth=".75">
          <path d="M13.303 19.505l-.036-13.543s5.751-2.64 9.085.171l.045 14.07s-3.874-2.795-9.094-.698zM3.327 6.69l-1.991.374 1.399 13.518 10.532-.753c-3.16-2.442-9.325-.752-9.325-.752L3.271 4.995s6.85-3.564 9.996.967" />
          <path d="M13.281 19.83l7.77.936.125-1.166" />
        </g>
        <path
          d="M19.925 7.785v.203l-.023.023-.024-.023v-.203h.047zm-.023-1.61a2.626 2.626 0 0 1 2.247-2.242 2.626 2.626 0 0 1-2.248-2.25 2.626 2.626 0 0 1-2.246 2.241 2.626 2.626 0 0 1 2.247 2.25zm3.868-2.266h.201l.024.024-.024.024h-.201V3.91zM19.878.285V.082l.024-.024.023.024v.203h-.047zM16.27 3.948h-.202l-.024-.024.024-.024h.202v.048z"
          stroke="#000"
          strokeWidth=".5"
          fill="#FFF"
        />
      </g>
    </Icon>
  );
}

BookColor.propTypes = {
  className: PropTypes.string,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

BookColor.defaultProps = {
  className: undefined,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};

export default BookColor;
