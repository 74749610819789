import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

export default function BlueMoldColor({
  className,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      viewBox="0 0 24 24"
      {...{ className, isFlipX, isFlipY, size }}
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M4.728 2.628h8.47c.515 1.336 1.27 2.004 2.264 2.004.994 0 1.75-.668 2.269-2.004h1.835v11.409c-1.223.337-1.835 1.07-1.835 2.2 0 1.13.612 1.863 1.835 2.2v3.59H4.728v-10.1c2.124-.68 3.186-1.784 3.186-3.31 0-1.527-1.062-2.593-3.186-3.199v-2.79z"
          fill="#26D1E9"
        />
        <g transform="translate(4.417 2.343)">
          <path
            d="M0 9.545c1.966 0 3.56-1.432 3.56-3.198C3.56 4.58 1.965 3.148 0 3.148h0M8.842 0c0 1.305 1.014 2.362 2.264 2.362 1.25 0 2.264-1.057 2.264-2.362M15.59 11.767a2.207 2.207 0 00-2.214 2.2c0 1.215.99 2.2 2.213 2.2"
            stroke="#000"
            strokeWidth=".75"
          />
          <circle
            fill="#000"
            transform="rotate(180 5.627 10.84)"
            cx="5.627"
            cy="10.84"
            r="1.584"
          />
          <circle
            fill="#000"
            transform="rotate(180 3.825 15.142)"
            cx="3.825"
            cy="15.142"
            r="1"
          />
          <circle
            fill="#000"
            transform="rotate(180 12.135 6.164)"
            cx="12.135"
            cy="6.164"
            r="1"
          />
          <path
            d="M.373 3.505V.36h8.83m3.795 0h2.213v11.595m0 3.884v3.919H.373V9.214"
            stroke="#000"
            strokeWidth=".75"
          />
        </g>
      </g>
    </Icon>
  );
}

BlueMoldColor.propTypes = {
  className: PropTypes.string,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

BlueMoldColor.defaultProps = {
  className: undefined,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};
