import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function Kittie({ className, color, size, ...props }) {
  return (
    <Icon className={className} viewBox="0 0 24 24" size={size} {...props}>
      <g
        id="Library"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="library-/icon-/cat-/default" stroke="#000000" strokeWidth="0.75">
          <g id="Group" transform="translate(0.000000, 2.000000)">
            <path
              d="M1.09762932,14.7559447 L1.11402378,14.7644352 C1.22373231,14.8247224 1.3059511,14.8285883 1.55029868,14.793763 C1.61749627,14.7841563 1.65129614,14.7795992 1.69264699,14.7750279 C2.17777515,14.7213974 2.5457403,14.8690338 2.90278302,15.358154 C3.42716825,16.07652 4.49730752,17.1233109 5.77155382,17.9760728 C7.65534036,19.2367565 9.75253058,19.9509353 12.0124937,19.8986784 C14.5789367,19.8393347 16.7988264,18.9746318 18.7135145,17.5539616 C19.2750671,17.1372979 19.7803871,16.6940489 20.2477649,16.2263023 C20.423668,16.0502605 20.5864668,15.8785862 20.7507531,15.6981551 C20.8446517,15.5950289 21.0891181,15.320279 21.0818562,15.3283451 C21.2863374,15.1012181 21.5014723,14.9384105 21.7352185,14.8302894 C22.0528278,14.6833768 22.2935513,14.6536483 22.8188962,14.6471753 C22.8372285,14.6469495 22.8372285,14.6469495 22.8554677,14.6467184 C22.9110095,14.6460037 22.8805961,14.6675808 22.8752808,14.6927642 C22.8830238,14.6560787 22.8568439,14.5531036 22.7889111,14.4340151 C22.5189405,13.9607476 22.2265171,13.3524648 21.9034609,12.6049183 C21.6954534,12.1235927 21.4775214,11.5912487 21.235331,10.9773734 C21.0005904,10.382381 20.2771151,8.49424077 20.2187711,8.34439947 C19.8787147,7.47105327 19.6634705,6.96688076 19.5245446,6.73257369 C17.9452523,4.07078837 15.3060192,2.72163804 12.0211625,2.72163804 C8.53458832,2.72163804 5.93589615,4.03577311 4.33588494,6.73247872 C4.19987138,6.96187385 3.99728089,7.46458479 3.68562505,8.33052524 C3.61694326,8.52135864 2.96346407,10.3755701 2.75087722,10.9583624 C2.39340786,11.9383403 2.08488013,12.7227659 1.78909427,13.3836639 C1.60509513,13.7947879 1.4292582,14.1499134 1.25963166,14.449059 C1.11798579,14.6988591 1.11395159,14.7638945 1.09762932,14.7559447 Z"
              id="Stroke-12"
            />
            <path
              d="M7.80484925,3.4240894 C5.367089,2.16189896 3.9076352,1.39210483 3.42648786,1.11470701 C2.70476686,0.698610276 1.56173097,1.11470701 1.81488714,2.41219219 C1.98365792,3.2771823 2.47043571,5.70422976 3.2752205,9.69333455"
              id="Shape"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.3167726,3.40504643 C19.8790123,2.14285599 18.4195585,1.37306186 17.9384112,1.09566404 C17.2166902,0.679567307 16.0736543,1.09566404 16.3268105,2.39314922 C16.4955812,3.25813934 16.982359,5.68518679 17.7871438,9.67429158"
              id="Shape"
              strokeLinecap="round"
              strokeLinejoin="round"
              transform="translate(19.303833, 5.307340) scale(-1, 1) translate(-19.303833, -5.307340) "
            />
          </g>
        </g>
      </g>
    </Icon>
  );
}

Kittie.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
};

Kittie.defaultProps = {
  className: undefined,
  color: css.colorBlack,
  size: 18,
};

export default Kittie;
