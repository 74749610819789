import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function CheeseSlice({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g
        stroke={isCssColor ? 'currentColor' : color}
        strokeWidth=".75"
        fill="none"
        fillRule="evenodd"
      >
        <path d="M1.375 4.456v5.271L12 21.572 22.625 9.727v-5.27L12 16.396 1.375 4.457zM12 16.29v5.202M1.073 3.872c3.2-.91 6.829-1.365 10.884-1.365 1.16 0 2.286.037 3.377.112m3.565.396c.92.144 2.682.462 3.942.857-2.592-.596-3.931-.9-4.019-.913m.4-.089a2.14 2.14 0 1 1-4.237-.596" />
      </g>
    </Icon>
  );
}

CheeseSlice.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

CheeseSlice.defaultProps = {
  className: undefined,
  color: css.colorGrey900,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};

export default CheeseSlice;
