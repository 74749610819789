import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

function BattleColor({ className, isFlipX, isFlipY, size, ...props }) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g fillRule="nonzero">
          <path
            d="M13.935 14.29c.58-.922.824-1.922-.164-2.443-.988-.52-1.676.655-2.077 1.182l-4.448 6.256c-.474.611-.514 1.353.154 1.81.538.369 1.46.346 2.09-.466.42-.542 1.902-2.655 4.445-6.339z"
            fill="#A2F76B"
          />
          <path
            d="M19.56 7.276c1.91-2.637 2.996-2.438 2.996-2.438l-.965-.755.926-.862c-1.538-.823-3.428-.465-4.603.871-.956.94-4.064 5.575-4.064 5.575a1.695 1.695 0 0 0-.194 1.631c.221.538.703.91 1.266.978a1.576 1.576 0 0 0 1.46-.654l3.178-4.346zM7.529 5.356c3.758 3.01 5.27 4.597 2.064 7.155-2.19 1.75-4.256 1.411-6.41-3.182C1.644 5.951 1.461 2.523 1.73 2.318c.09-.061 3.009.828 5.799 3.038z"
            fill="#EFEFEF"
          />
          <path
            d="M11.123 12.377c-.854-.867-1.867-.755-2.305.239-.438.993.799 2.16.977 2.374l4.605 5.153c.546.694 1.719 1.638 2.748.614.469-.652.505-1.1-.222-2.021-.485-.614-2.42-2.734-5.803-6.359z"
            fill="#D7FFBD"
          />
          <path
            d="M10.187 15.433L7.166 19.68c-.379.488-.261 1.172.28 1.542.495.339 1.246.217 1.742-.423.302-.39 1.14-1.572 2.505-3.534l-1.506-1.832zm3.4-.902l.364-.525c.647-1.03.664-1.73-.034-2.098-.62-.327-1.241.09-1.934.997h0l1.604 1.626zm5.89-7.564c1.072-1.428 1.98-2.204 2.695-2.53l-.772-.581.915-.819a3.854 3.854 0 0 0-4.104 1.057c-.355.335-1.051 1.207-1.973 2.449A143.644 143.644 0 0 0 14.01 9.64l-.19.272a1.352 1.352 0 0 0-.16 1.348 1.368 1.368 0 0 0 2.35.279l3.467-4.573z"
            stroke="#000"
            strokeWidth=".75"
          />
        </g>
        <g stroke="#000" strokeWidth=".75">
          <path
            d="M7.295 5.65a18.391 18.391 0 0 0-3.832-2.323 13.956 13.956 0 0 0-1.3-.515 6.33 6.33 0 0 0-.244-.076c-.073.687.514 4.043 1.604 6.434 1.91 4.074 3.649 4.796 5.836 3.048 1.453-1.16 1.849-2.038 1.466-2.973-.36-.882-1.302-1.811-3.53-3.595zm3.679 6.808c-.652-.66-1.536-.515-1.875.254-.148.335-.004.835.376 1.416.111.171.235.337.37.502.077.095.257.304.24.283l4.747 5.361c.643.816 1.605 1.12 2.24.495.44-.611.44-1.086-.115-1.79-.475-.601-2.477-2.785-5.983-6.521z"
            fillRule="nonzero"
          />
          <path d="M14.223 19.415l1.651-1.467" />
        </g>
        <path
          d="M18.78 17.968v.188l-.03.031-.031-.03v-.189h.062zm-.03-1.606a2.548 2.548 0 0 1 2.114-2.109 2.548 2.548 0 0 1-2.115-2.117 2.548 2.548 0 0 1-2.114 2.109 2.548 2.548 0 0 1 2.115 2.117zm3.73-2.14h.187l.031.031-.031.032h-.187v-.063zm-3.761-3.487v-.188l.03-.031.032.031v.188h-.062zm-3.472 3.541h-.187l-.032-.031.032-.032h.187v.063z"
          stroke="#000"
          strokeWidth=".5"
          fill="#FFF"
        />
      </g>
    </Icon>
  );
}

BattleColor.propTypes = {
  className: PropTypes.string,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

BattleColor.defaultProps = {
  className: undefined,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};

export default BattleColor;
