import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function AffinityWind({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g fill={isCssColor ? 'currentColor' : color} fillRule="evenodd">
        <path d="M18.776 4.471H15.98c.387.347.746.737 1.07 1.168 1.565 2.093 2.078 4.762 1.407 7.324-1.206 4.602-5.359 5.324-7.623 5.023-2.54-.336-5.454-2.117-5.31-6.03.06-1.592.584-2.846 1.56-3.726 1.178-1.063 2.806-1.37 3.613-1.316 1.22.08 2.218.552 2.888 1.366.642.781.93 1.847.79 2.923-.196 1.518-1.49 2.394-2.634 2.354-1.811-.055-1.811-1.082-1.811-1.469 0-.56.231-.775.406-.888.166-.107.381-.17.607-.191V8.925c-.625.027-1.238.202-1.739.525-.876.567-1.358 1.504-1.358 2.638 0 2.12 1.468 3.48 3.831 3.552 2.069.061 4.417-1.48 4.765-4.17.213-1.645-.242-3.29-1.248-4.513-1.029-1.252-2.577-2.005-4.36-2.123-1.071-.069-3.362.24-5.145 1.848C4.3 7.937 3.522 9.734 3.443 11.88c-.184 4.97 3.443 7.686 7.119 8.173.401.053.813.08 1.23.08 3.542 0 7.457-1.963 8.682-6.64.824-3.148.204-6.43-1.698-9.02" />
      </g>
    </Icon>
  );
}

AffinityWind.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

AffinityWind.defaultProps = {
  className: undefined,
  color: css.colorBlack,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 18,
};

export default AffinityWind;
