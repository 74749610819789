import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

function CheeseWheelColor({ className, isFlipX, isFlipY, size, ...props }) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path fill="#FFB05D" d="M4.56 3.753l3.908.12 1.628 5.3-1.178 6.298z" />
        <path
          d="M11.642 2.259l6.498.506c2.793 2.032 4.456 5.368 4.45 8.928.073 6.214-2.6 9.005-4.95 10.309 0 0-5.03 1.399-7.966.672-2.936-.726 1.257-16.61 1.968-20.415z"
          fill="#FFB05D"
        />
        <path
          d="M4.56 3.747a10.696 10.696 0 0 0-4.058 8.577c0 5.708 4.119 10.333 9.2 10.333 5.081 0 9.206-4.625 9.206-10.333 0-4.899-3.037-9.006-7.114-10.065L9.23 14.853 4.56 3.747z"
          fill="#FFE877"
        />
        <g>
          <path
            d="M4.798 3.786A10.696 10.696 0 0 0 .74 12.363c0 5.708 4.119 10.332 9.2 10.332 5.081 0 9.206-4.624 9.206-10.332 0-4.899-3.037-9.006-7.114-10.065L9.467 14.892 4.798 3.786z"
            stroke="#000"
            strokeWidth=".75"
          />
          <path
            stroke="#000"
            strokeWidth=".75"
            d="M4.906 3.584l3.22.109 2.31 6.025M11.827 2.215l6.056.557a10.914 10.914 0 0 1 4.697 8.96c.078 6.214-2.732 9.005-5.2 10.309 0 0-4.54.762-8.343.596"
          />
          <path
            d="M10.59 19.109s-.494.503-1.16.489c-.758-.017-1.579-.246-1.423-.584.155-.337.333-.195.834-.09.582.121.817.01 1.305-.262.487-.273.831.084.445.447zM16.21 10.16c-.107-.65-.758-.507-.98-.215-.22.291-.43 2.893-.107 3.178a.522.522 0 0 0 .909-.357c.154-.86.214-1.734.179-2.607zM3.244 10.738s.472-.4.723.434c.251.833.257 2.732-.18 2.673-.436-.06-.471-.977-.543-1.786a6.057 6.057 0 0 1 0-1.321z"
            fill="#000"
            fillRule="nonzero"
          />
        </g>
        <path
          d="M19.906 7.75v.203l-.024.024-.023-.024V7.75h.047zm-.023-1.61a2.626 2.626 0 0 1 2.246-2.242 2.626 2.626 0 0 1-2.247-2.25 2.626 2.626 0 0 1-2.246 2.241 2.626 2.626 0 0 1 2.247 2.25zm3.867-2.266h.202l.024.024-.024.025h-.202v-.049zM19.859.25V.047l.023-.024.024.024V.25h-.047zM16.25 3.913h-.202l-.024-.024.024-.024h.202v.048z"
          stroke="#000"
          strokeWidth=".5"
          fill="#FFF"
        />
      </g>
    </Icon>
  );
}

CheeseWheelColor.propTypes = {
  className: PropTypes.string,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

CheeseWheelColor.defaultProps = {
  className: undefined,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};

export default CheeseWheelColor;
