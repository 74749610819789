import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function ShareLink({ className, color, size, ...props }) {
  return (
    <Icon className={className} viewBox="0 0 24 24" size={size} {...props}>
      <g
        id="Library"
        stroke="none"
        strokeWidth="1.5"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <g id="library-/icon-/link-/default" stroke="#000000" strokeWidth="1.5">
          <g id="Group" transform="translate(2.000000, 1.500000)">
            <path
              d="M10.6930372,4.47607719 C12.9149005,2.02255569 10.5045166,4.76005129 13.8171194,1.25378807 C17.1297222,-2.25247515 22.194447,2.345629 18.9646317,5.86051974 C15.7348165,9.37541048 15.0348855,10.1839239 13.8171194,11.2192897 C12.5993533,12.2546554 10.2652303,12.4020826 8.52191116,10.7202148"
              id="Path-2"
            />
            <path
              d="M2.17112606,12.5807198 C4.39298931,10.1271983 1.98260544,12.8646939 5.29520824,9.35843069 C8.60781103,5.85216747 13.6725358,10.4502716 10.4427206,13.9651624 C7.21290531,17.4800531 6.51297433,18.2885666 5.29520824,19.3239323 C4.07744214,20.359298 1.74331917,20.5067252 -4.52970994e-14,18.8248575"
              id="Path-2"
              transform="translate(5.738777, 14.101149) rotate(-180.000000) translate(-5.738777, -14.101149) "
            />
          </g>
        </g>
      </g>
    </Icon>
  );
}

ShareLink.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
};

ShareLink.defaultProps = {
  className: undefined,
  color: css.colorBlack,
  size: 18,
};

export default ShareLink;
