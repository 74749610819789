import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function Refresh({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g
        stroke={isCssColor ? 'currentColor' : color}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <path d="M19.333 5.482C17.775 3.161 15.331 2 12 2A9.98 9.98 0 0 0 2 12c0 5.523 4.477 10 10 10s10-4.477 10-10" />
        <path strokeLinejoin="round" d="M19.77 1.542v4.593h-4.273" />
      </g>
    </Icon>
  );
}

Refresh.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

Refresh.defaultProps = {
  className: undefined,
  color: css.colorGrey900,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};

export default Refresh;
