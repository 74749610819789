import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function AffinityWater({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g fillRule="evenodd" fill={isCssColor ? 'currentColor' : color}>
        <path
          d="M16.038 16.221c-.098-.055-.2-.115-.307-.177-1.422-.83-4.067-2.374-7.6.248-1.762 1.307-3.75.24-4.857-.591v2.436c.921.482 1.981.83 3.08.83.997 0 2.026-.287 3.008-1.015 2.438-1.81 4.04-.874 5.327-.123.117.068.23.134.337.194 1.306.734 3.223 1.417 5.825.047v-2.43c-2.556 1.847-4 1.038-4.813.581m0-4.769c-.098-.055-.2-.115-.307-.177-1.422-.83-4.067-2.373-7.6.248-1.762 1.307-3.75.241-4.857-.59v2.435c.922.482 1.981.83 3.08.83.997 0 2.026-.286 3.008-1.015 2.438-1.809 4.04-.874 5.327-.123.118.068.23.134.337.194 1.307.734 3.223 1.417 5.825.048V10.87c-2.555 1.847-4 1.038-4.813.581m0-4.768a12.67 12.67 0 0 1-.307-.177c-1.422-.829-4.067-2.372-7.6.249-1.762 1.306-3.75.24-4.857-.592V8.6c.922.482 1.981.83 3.08.83.997 0 2.026-.286 3.008-1.015 2.438-1.809 4.04-.874 5.327-.123.118.069.23.134.337.194 1.307.735 3.223 1.417 5.825.048v-2.43c-2.555 1.846-4 1.038-4.813.58"
          fillRule="evenodd"
        />
      </g>
    </Icon>
  );
}

AffinityWater.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

AffinityWater.defaultProps = {
  className: undefined,
  color: css.colorBlack,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 18,
};

export default AffinityWater;
