import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function Cauldron({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <g transform="translate(.68 10.115)">
          <path
            stroke={isCssColor ? 'currentColor' : color}
            d="M18.746 4.611l.367.59a1.492 1.492 0 0 0 2.123.488c.721-.53.917-1.524.451-2.288L20.59.719M1.966 1.571L.85 4.083c-.38.808-.221 1.692.554 2.134.776.442 1.826.023 2.212-.669.19-.29.334-.507.43-.652M5.565 3.32L3.972 8.628a2.054 2.054 0 0 0 1.168 2.553A1.853 1.853 0 0 0 7.524 9.93l2.26-4.581M13.273 5.112L14.719 9.6a1.858 1.858 0 0 0 2.372 1.284 2.049 2.049 0 0 0 1.195-2.537L17.5 3.23"
            strokeWidth=".75"
          />
          <ellipse
            fill={isCssColor ? 'currentColor' : color}
            fillRule="nonzero"
            transform="rotate(-67.75 5.578 9.888)"
            cx="5.578"
            cy="9.888"
            rx="1"
            ry="1"
          />
          <ellipse
            fill={isCssColor ? 'currentColor' : color}
            fillRule="nonzero"
            transform="rotate(-22.25 16.544 9.362)"
            cx="16.544"
            cy="9.362"
            rx="1"
            ry="1"
          />
          <path d="M8.65 8.01c1.135.626 4.854.493 5.497 0" strokeWidth=".75" />
          <path
            stroke={isCssColor ? 'currentColor' : color}
            strokeWidth=".75"
            d="M8.65 8.01c1.135.626 4.854.493 5.497 0"
          />
        </g>
        <path
          stroke={isCssColor ? 'currentColor' : color}
          strokeWidth=".75"
          d="M5.572 15.615C3.402 13.93-.114 7.864 3.402 4.448c1.945-1.889 5.93-.831 8.437-.792 2.507.04 7.227-1.487 9.184 1.072 2.324 3.04-1.22 9.973-2.493 10.887"
        />
      </g>
    </Icon>
  );
}

Cauldron.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

Cauldron.defaultProps = {
  className: undefined,
  color: css.colorGrey900,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};

export default Cauldron;
