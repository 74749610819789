import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function Lock({ className, color, size, ...props }) {
  return (
    <Icon className={className} viewBox="0 0 24 24" size={size} {...props}>
      <g
        id="Library"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g id="library-/icon-/lock-/default" stroke="#000000" strokeWidth="1">
          <path
            d="M5.5,7 L18.5,7 C18.7761424,7 19,7.22385763 19,7.5 L19,20 C19,21.1045695 18.1045695,22 17,22 L7,22 C5.8954305,22 5,21.1045695 5,20 L5,7.5 C5,7.22385763 5.22385763,7 5.5,7 Z"
            id="Rectangle"
            strokeLinejoin="round"
          />
          <path
            d="M17,8.5 L17,6.33083974 C17,3.66284062 14.7614237,1.5 12,1.5 C9.23857625,1.5 7,3.66284062 7,6.33083974 L7,8.5"
            id="Shape"
            strokeLinecap="round"
          />
          <circle id="Oval" cx="12" cy="14.75" r="1.125" />
          <path d="M12,16.25 L12,18.25" id="Line-7" strokeLinecap="round" />
          <path
            d="M5,8.5 L5,9.13879395 C5,9.41493632 5.22385763,9.63879395 5.5,9.63879395 L18.5,9.63879395 C18.7761424,9.63879395 19,9.41493632 19,9.13879395 L19,8.5"
            id="Line-8"
            strokeLinecap="square"
          />
        </g>
      </g>
    </Icon>
  );
}

Lock.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
};

Lock.defaultProps = {
  className: undefined,
  color: css.colorBlack,
  size: 18,
};

export default Lock;
