import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

function CheeseSliceColor({ className, isFlipX, isFlipY, size, ...props }) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          fill="#FFB05D"
          d="M1.43 3.774l13.917-.199c-.009.853.565 1.27 1.723 1.254 1.158-.017 1.913-.453 2.265-1.31l3.345-.048-.024 6.198-10.567 11.655L1.427 9.593l.002-5.82z"
        />
        <path
          fill="#FFE877"
          d="M1.402 3.85c3.32-.943 6.832-1.415 10.539-1.415 1.098 0 2.19.108 3.293.101.023 0-.11 1.742 1.77 2.11 1.45.283 1.614-1.752 2.282-1.656.745.107 1.866.511 3.362 1.213L11.941 15.826 1.4 3.851z"
        />
        <path
          stroke="#000"
          strokeWidth=".75"
          d="M1.375 4.456v5.271L12 21.572 22.625 9.727v-5.27L12 16.396 1.375 4.457zM12 16.29v5.202M1.073 3.872c3.2-.91 6.829-1.365 10.884-1.365 1.16 0 2.286.037 3.377.112m3.565.396c.92.144 2.682.462 3.942.857-2.592-.596-3.931-.9-4.019-.913M19.222 2.87a2.14 2.14 0 1 1-4.237-.596"
        />
      </g>
    </Icon>
  );
}

CheeseSliceColor.propTypes = {
  className: PropTypes.string,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

CheeseSliceColor.defaultProps = {
  className: undefined,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};

export default CheeseSliceColor;
