import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function Audio({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g
        transform="translate(1.19 3.395)"
        stroke={isCssColor ? 'currentColor' : color}
        strokeWidth=".75"
        fill="none"
        fillRule="evenodd"
      >
        <path d="M13.244.019c-.314-.051-.64.002-.926.157a.43.43 0 0 0-.058.036l-5.943 4.51H1.653m0 8.263l4.654-.002 5.94 4.895a.422.422 0 0 0 .07.047c.215.117.455.175.694.175" />
        <ellipse cx="13.097" cy="9.05" rx="1.951" ry="9.05" />
        <path
          d="M17.31 4.533c.684.58 1.839 2.06 1.839 4.197 0 2.136-1.155 3.346-1.838 3.803M19.73 2.533c.743.87 2 3.09 2 6.295 0 3.204-1.257 5.02-2 5.705"
          strokeLinecap="round"
        />
        <path d="M1.89 4.712C.846 4.712 0 6.565 0 8.85c0 2.285.846 4.137 1.89 4.137M6.596 4.5c-.566.32-1.399 2.142-1.451 4.234m0-.021c-.073 2.876.926 4.242 1.46 4.538" />
      </g>
    </Icon>
  );
}

Audio.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

Audio.defaultProps = {
  className: undefined,
  color: css.colorGrey900,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};

export default Audio;
