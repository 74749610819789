import React from 'react';
import PropTypes from 'prop-types';
import cx from 'classnames';
import css from './Icon.css';

function Icon({
  className,
  children,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <svg
      width={size}
      height={size}
      className={cx(
        css.icon,
        {
          [css.flipX]: isFlipX,
          [css.flipY]: isFlipY,
        },
        className,
      )}
      {...props}
    >
      {children}
    </svg>
  );
}

Icon.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

Icon.defaultProps = {
  className: undefined,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};

export default Icon;
