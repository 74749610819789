import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function ClashWin({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      {...{
        className,
        isFlipX: !isFlipX,
        isFlipY,
        viewBox: '0 0 24 24',
        size,
        ...props,
      }}
    >
      <path
        d="M17.5 22.75L6 12 17.5 1.25"
        stroke={isCssColor ? 'currentColor' : color}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </Icon>
  );
}

ClashWin.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

ClashWin.defaultProps = {
  className: undefined,
  color: css.colorBlack,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 18,
};

export default ClashWin;
