import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function SmileyColor({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          d="M22.303 8.41c1.953 5.72-1.1 11.94-6.82 13.893-5.72 1.953-11.94-1.1-13.893-6.82-1.953-5.72 1.1-11.94 6.82-13.893 5.72-1.953 11.94 1.1 13.893 6.82z"
          stroke="#000"
          strokeWidth=".75"
          fill="#FFF24D"
        />
        <path
          d="M7.898 8.83c.11.556.064 1.036-.018 1.499-.038.2-.089.393-.15.582.163.11.322.233.474.367.348.316.678.668.932 1.175-.557.109-1.037.063-1.499-.019a4.913 4.913 0 0 1-.58-.149c-.11.163-.233.322-.368.475-.315.347-.667.677-1.176.93-.111-.557-.066-1.038.016-1.5a4.93 4.93 0 0 1 .15-.58 4.868 4.868 0 0 1-.473-.366c-.348-.316-.677-.668-.93-1.177.557-.111 1.037-.066 1.5.016.2.039.393.09.581.15.111-.162.233-.32.367-.472.316-.348.668-.677 1.174-.931zm8.804-3.006c.109.556.063 1.036-.019 1.498-.038.2-.089.394-.15.583.163.11.322.233.475.367.347.316.677.668.93 1.175-.556.109-1.036.063-1.498-.019a4.916 4.916 0 0 1-.58-.15c-.11.163-.233.322-.367.475-.316.348-.668.678-1.177.93-.111-.556-.066-1.037.016-1.5a4.93 4.93 0 0 1 .15-.579 4.857 4.857 0 0 1-.473-.366c-.348-.316-.677-.668-.93-1.177.557-.111 1.038-.066 1.5.016.2.039.393.09.581.15.111-.162.233-.32.367-.472.316-.348.668-.677 1.175-.931z"
          fill="#000"
        />
        <path
          d="M9.015 22.496a6.232 6.232 0 1 1 11.795-4.028"
          stroke="#000"
          strokeWidth=".75"
        />
      </g>
    </Icon>
  );
}

SmileyColor.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

SmileyColor.defaultProps = {
  className: undefined,
  color: css.colorGrey900,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};

export default SmileyColor;
