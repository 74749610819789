import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

function Warning({ className, size, ...props }) {
  return (
    <Icon className={className} viewBox="0 0 24 24" size={size} {...props}>
      <g fill="none" fillRule="evenodd">
        <path
          stroke="#000"
          strokeWidth=".75"
          d="M23.13 18.275c.696 1.114-.162 2.499-1.526 2.5H2.372c-1.346 0-2.195-1.39-1.502-2.5L10.486 2.84c.678-1.087 2.35-1.087 3.028 0l9.616 15.435z"
        />
        <path
          stroke="#FFF"
          strokeWidth=".75"
          d="M22.494 18.672L12.877 3.236c-.385-.616-1.37-.616-1.755 0L1.507 18.672c-.372.596.091 1.353.865 1.353h19.231c.796 0 1.264-.756.89-1.353z"
          strokeLinejoin="square"
        />
        <path d="M12.018 7.5v7.25" stroke="#000" strokeLinecap="round" />
        <circle fill="#000" cx="12" cy="17.25" r="1" />
      </g>
    </Icon>
  );
}

Warning.propTypes = {
  className: PropTypes.string,
  size: PropTypes.number,
};

Warning.defaultProps = {
  className: undefined,
  size: 24,
};

export default Warning;
