import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function DuelCompleteColor({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <circle fill="#FFE96E" cx="11.292" cy="11.254" r="10" />
        <path
          d="M11.375 1c1.869 0 3.665.495 5.24 1.418l.312.19-.402.634a9.577 9.577 0 0 0-5.15-1.492 9.625 9.625 0 1 0 0 19.25c2.425 0 4.64-.897 6.333-2.377a2.38 2.38 0 0 0-2.045-1.587l-.18-.009h-1.3v-.451h1.281a2.376 2.376 0 0 0 2.368-2.206l.006-.161v-1.297h.453v1.306a2.371 2.371 0 0 0 1.33 2.124A9.577 9.577 0 0 0 21 11.375a9.602 9.602 0 0 0-.63-3.432l-.145-.36.689-.295c.55 1.28.836 2.664.836 4.087 0 1.89-.506 3.663-1.39 5.19l.151.014.154.006h1.517v.451h-1.517c-.229 0-.45.033-.658.093-.48.723-1.051 1.381-1.694 1.96l-.017.156-.005.158v1.51h-.453v-1.421a10.331 10.331 0 0 1-6.463 2.258C5.645 21.75 1 17.105 1 11.375S5.645 1 11.375 1zm6.687 14.432a2.886 2.886 0 0 1-1.374 1.37c.538.253.984.668 1.276 1.181l.103.197.102-.196a2.889 2.889 0 0 1 1.075-1.071l.006-.003.1-.145a2.876 2.876 0 0 1-1.288-1.333zM19.868 2.2l1.553 1.26-10.818 13.333a1 1 0 0 1-1.459.102l-.085-.09-4.737-5.657 1.534-1.284 3.958 4.727L19.868 2.2zM6.908 4.279v.652a1.187 1.187 0 0 0 1.188 1.184h.758v.226h-.758a1.187 1.187 0 0 0-1.187 1.183v.755h-.227v-.757c0-.65-.525-1.18-1.177-1.186h-.65V6.11h.64a1.187 1.187 0 0 0 1.187-1.183v-.648h.227zM6.795 6a1.697 1.697 0 0 1-.224.223c.083.069.16.145.228.228l.106-.117.117-.106A1.697 1.697 0 0 1 6.794 6z"
          fill="#000"
          fillRule="nonzero"
        />
      </g>
    </Icon>
  );
}

DuelCompleteColor.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

DuelCompleteColor.defaultProps = {
  className: undefined,
  color: css.colorGrey900,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};

export default DuelCompleteColor;
