import AffinityFire from './AffinityFire';
import AffinityNeutral from './AffinityNeutral';
import AffinityWater from './AffinityWater';
import AffinityWind from './AffinityWind';
import Arrow from './Arrow';
import Audio from './Audio';
import AudioOff from './AudioOff';
import Battle from './Battle';
import BattleColor from './BattleColor';
import BlueMold from './BlueMold';
import BlueMoldColor from './BlueMoldColor';
import Book from './Book';
import BookColor from './BookColor';
import Cauldron from './Cauldron';
import CauldronColor from './CauldronColor';
import Chat from './Chat';
import CheeseSlice from './CheeseSlice';
import CheeseSliceColor from './CheeseSliceColor';
import CheeseWheel from './CheeseWheel';
import CheeseWheelColor from './CheeseWheelColor';
import ClashTie from './ClashTie.js';
import ClashWin from './ClashWin.js';
import ClashWinCosmic from './ClashWinCosmic.js';
import ClashWinCosmicUltimate from './ClashWinCosmicUltimate.js';
import Close from './Close';
import Completed from './Completed';
import Drop from './Drop';
import DuelActive from './DuelActive';
import DuelActiveColor from './DuelActiveColor';
import DuelComplete from './DuelComplete';
import DuelCompleteColor from './DuelCompleteColor';
import Facebook from './Facebook';
import FaqDuel from './FaqDuel';
import FaqSummon from './FaqSummon';
import FaqTournament from './FaqTournament';
import Filter from './Filter';
import Info from './Info';
import Kittie from './Kittie';
import Link from './Link';
import Lock from './Lock';
import MagicHat from './MagicHat';
import Mail from './Mail';
import More from './More';
import Notifications from './Notifications';
import PausedColor from './PausedColor';
import Plus from './Plus';
import Prize from './Prize';
import PrizeColor from './PrizeColor';
import Public from './Public';
import Received from './Received';
import ReceivedColor from './ReceivedColor';
import Refresh from './Refresh';
import Search from './Search';
import SearchColor from './SearchColor';
import Sent from './Sent';
import SentColor from './SentColor';
import Settings from './Settings';
import Share from './Share';
import ShareLink from './ShareLink';
import Smiley from './Smiley';
import SmileyColor from './SmileyColor';
import Sort from './Sort';
import Tower from './Tower';
import TowerColor from './TowerColor';
import Twitter from './Twitter';
import WarningColor from './WarningColor';
import Warning from './Warning';

export default {
  AffinityFire,
  AffinityNeutral,
  AffinityWater,
  AffinityWind,
  Arrow,
  Audio,
  AudioOff,
  Battle,
  BattleColor,
  BlueMold,
  BlueMoldColor,
  Book,
  BookColor,
  Cauldron,
  CauldronColor,
  Chat,
  CheeseSlice,
  CheeseSliceColor,
  CheeseWheel,
  CheeseWheelColor,
  ClashTie,
  ClashWin,
  ClashWinCosmic,
  ClashWinCosmicUltimate,
  Close,
  Completed,
  Drop,
  DuelActive,
  DuelActiveColor,
  DuelComplete,
  DuelCompleteColor,
  Facebook,
  FaqDuel,
  FaqSummon,
  FaqTournament,
  Filter,
  Info,
  Kittie,
  MagicHat,
  Link,
  Lock,
  Mail,
  More,
  Notifications,
  PausedColor,
  Plus,
  Prize,
  PrizeColor,
  Public,
  Received,
  ReceivedColor,
  Refresh,
  Search,
  SearchColor,
  Sent,
  SentColor,
  Settings,
  Share,
  ShareLink,
  Smiley,
  SmileyColor,
  Sort,
  Tower,
  TowerColor,
  Twitter,
  WarningColor,
  Warning,
};
