import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

function PausedColor({ className, isFlipX, isFlipY, size, ...props }) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g fill="#FF809B" fillRule="evenodd">
        <rect x="6.25" y="3" width="3.5" height="18" rx=".25" />
        <rect x="14.25" y="3" width="3.5" height="18" rx=".25" />
      </g>
    </Icon>
  );
}

PausedColor.propTypes = {
  className: PropTypes.string,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

PausedColor.defaultProps = {
  className: undefined,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};

export default PausedColor;
