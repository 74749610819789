import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

export default function BlueMold({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      viewBox="0 0 24 24"
      {...{ className, isFlipX, isFlipY, size }}
      {...props}
    >
      <g transform="rotate(180 10.05 11.05)" fill="none" fillRule="evenodd">
        <path
          d="M15.683 10.211c-1.966 0-3.56 1.432-3.56 3.199 0 1.766 1.594 3.198 3.56 3.198h0M6.84 19.757c0-1.305-1.013-2.363-2.263-2.363-1.251 0-2.265 1.058-2.265 2.363M.093 7.99a2.207 2.207 0 002.214-2.2c0-1.216-.991-2.201-2.214-2.201"
          stroke={isCssColor ? 'currentColor' : color}
          strokeWidth=".75"
        />
        <circle
          fill={isCssColor ? 'currentColor' : color}
          cx="10.056"
          cy="8.916"
          r="1.584"
        />
        <circle
          fill={isCssColor ? 'currentColor' : color}
          cx="11.858"
          cy="4.614"
          r="1"
        />
        <circle
          fill={isCssColor ? 'currentColor' : color}
          cx="3.547"
          cy="13.592"
          r="1"
        />
        <path
          d="M15.31 16.251v3.147H6.48m-3.795 0H.472V7.803m0-3.885V0H15.31v10.543"
          stroke={isCssColor ? 'currentColor' : color}
          strokeWidth=".75"
        />
      </g>
    </Icon>
  );
}

BlueMold.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

BlueMold.defaultProps = {
  className: undefined,
  color: css.colorBlack,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};
