import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function FaqTournament({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 33 30"
      size={size}
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2.62662 11.3994L4.69354 10.662L4.34647 8.85811C4.34647 8.85811 9.06577 6.45306 10.8653 6.36451C12.6648 6.27596 15.2159 8.16032 15.2159 8.16032C15.2159 8.16032 19.1427 6.24961 20.8355 6.08449C22.5283 5.91938 25.0062 6.84886 25.0062 6.84886L27.3516 21.6571L26.0101 21.1948L25.9971 22.7273L17.5567 23.0363L6.41422 25.6266L2.62662 11.3994Z"
        fill="#AC87F8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.34646 8.85815C4.34646 8.85815 9.06577 6.45309 10.8653 6.36455C12.6648 6.276 15.2159 8.16036 15.2159 8.16036C15.2159 8.16036 19.1427 6.24965 20.8355 6.08453C22.5283 5.91942 25.0062 6.8489 25.0062 6.8489L27.3516 21.6572C27.3516 21.6572 25.2765 20.56 23.1751 20.8559C21.0737 21.1518 17.5567 23.0363 17.5567 23.0363C17.5567 23.0363 15.4804 22.2256 13.6322 22.3673C11.784 22.5091 7.45124 23.8113 7.45124 23.8113L4.34646 8.85815Z"
        fill="#D8C9FC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.2159 8.16035C15.2159 8.16035 19.1427 6.24964 20.8355 6.08452C22.5283 5.91941 25.0062 6.84889 25.0062 6.84889L27.3516 21.6572C27.3516 21.6572 25.2765 20.56 23.1751 20.8559C21.0737 21.1518 17.5567 23.0363 17.5567 23.0363L15.2159 8.16035Z"
        fill="#EAE1FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5398 22.6832L15.2066 8.19858C15.2066 8.19858 20.9122 4.39893 24.9557 6.84202L27.3883 21.8891C27.3883 21.8891 22.7693 19.5543 17.5398 22.6832Z"
        stroke="black"
        strokeWidth="0.75"
      />
      <path
        d="M4.69353 10.6619L2.62661 11.3994L6.41421 25.6266L17.5567 23.0362C13.7602 20.9586 7.45123 23.8112 7.45123 23.8112L4.34646 8.85806C4.34646 8.85806 11.0712 3.88389 15.2066 8.19862"
        stroke="black"
        strokeWidth="0.75"
      />
      <path
        d="M17.5716 23.0338L26.0441 22.7188L25.9794 21.4503"
        stroke="black"
        strokeWidth="0.75"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.4076 29.1459L30.4208 27.215L28.9218 16.7763L23.761 16.5032L20.5525 17.2147L19.6745 27.8546L24.4076 29.1459Z"
        fill="#AEFFFF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.4076 29.1459L30.4208 27.215L28.9218 16.7763L23.761 16.5032L24.4076 29.1459Z"
        fill="#CCFFFF"
      />
      <path
        d="M30.0046 26.9548L24.3989 28.7548L20.0739 27.5749L20.9035 17.5209L23.7923 16.8804L28.5944 17.1345L30.0046 26.9548Z"
        stroke="black"
        strokeWidth="0.75"
      />
      <path
        d="M23.893 16.9535L24.3354 28.7538"
        stroke="black"
        strokeWidth="0.75"
        strokeLinecap="square"
      />
      <path
        d="M21.4388 22.6374L21.3954 23.3862"
        stroke="black"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
      <path
        d="M21.3197 24.7723L21.2762 25.5211"
        stroke="black"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
      <path
        d="M21.5557 20.5024L21.5123 21.2512"
        stroke="black"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
      <path
        d="M22.8324 22.722V23.4708"
        stroke="black"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
      <path
        d="M22.8266 25.0002V25.749"
        stroke="black"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
      <path
        d="M22.8266 20.5439V21.2927"
        stroke="black"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
      <path
        d="M25.2613 20.5024L25.3265 21.2484"
        stroke="black"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
      <path
        d="M25.6502 24.9021L25.7155 25.648"
        stroke="black"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
      <path
        d="M25.4167 22.724L25.482 23.4699"
        stroke="black"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
      <path
        d="M26.6089 20.3295L26.6741 21.0754"
        stroke="black"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
      <path
        d="M27.0769 24.5481L27.1421 25.294"
        stroke="black"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
      <path
        d="M26.8265 22.5042L26.8918 23.2501"
        stroke="black"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
      <path
        d="M27.8888 20.1785L27.954 20.9244"
        stroke="black"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
      <path
        d="M28.3738 24.1938L28.4391 24.9398"
        stroke="black"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
      <path
        d="M28.1286 22.2457L28.1939 22.9917"
        stroke="black"
        strokeWidth="0.75"
        strokeLinecap="round"
      />
      <path
        d="M21.8762 17.1241C21.9352 16.4466 22.1099 15.5004 22.6226 15.3155C23.4382 15.0213 25.1665 15.2754 25.1665 15.2754C25.1665 15.2754 27.0519 15.379 27.1521 16.1412C27.1928 16.4485 27.224 16.7117 27.2474 16.9231"
        stroke="black"
        strokeWidth="0.75"
      />
      <path
        d="M26.3098 15.8905C26.5165 15.8905 26.6842 15.7079 26.6842 15.4827C26.6842 15.2574 26.5165 15.0748 26.3098 15.0748C26.103 15.0748 25.9354 15.2574 25.9354 15.4827C25.9354 15.7079 26.103 15.8905 26.3098 15.8905Z"
        fill="black"
      />
      <path
        d="M23.8494 14.6068L26.4167 15.0882L26.3532 15.8905L23.8494 15.6732"
        fill="black"
      />
      <path
        d="M23.8494 15.6765C24.119 15.6765 24.3375 15.4371 24.3375 15.1417C24.3375 14.8463 24.119 14.6068 23.8494 14.6068C23.5799 14.6068 23.3614 14.8463 23.3614 15.1417C23.3614 15.4371 23.5799 15.6765 23.8494 15.6765Z"
        fill="black"
      />
    </Icon>
  );
}

FaqTournament.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

FaqTournament.defaultProps = {
  className: undefined,
  color: css.colorGrey900,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};

export default FaqTournament;
