import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function Settings({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <path
        stroke={isCssColor ? 'currentColor' : color}
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        d="M4.263 14.078c.18.673.449 1.32.798 1.923l-1.15 1.428a1.614 1.614 0 0 0 .116 2.154l.39.39a1.614 1.614 0 0 0 2.154.117l1.428-1.151a7.94 7.94 0 0 0 1.923.798l.197 1.822c.089.82.78 1.441 1.605 1.441h.552c.825 0 1.516-.622 1.605-1.44l.197-1.823A7.94 7.94 0 0 0 16 18.94l1.428 1.15c.642.517 1.571.468 2.154-.116l.39-.39a1.614 1.614 0 0 0 .117-2.154l-1.151-1.428c.349-.604.618-1.25.798-1.923l1.822-.197c.82-.089 1.441-.78 1.441-1.605v-.552c0-.825-.622-1.516-1.44-1.605l-1.823-.197A7.977 7.977 0 0 0 18.94 8l1.15-1.428a1.614 1.614 0 0 0-.116-2.154l-.39-.39a1.614 1.614 0 0 0-2.154-.117l-1.428 1.151a7.94 7.94 0 0 0-1.923-.798L13.88 2.44A1.615 1.615 0 0 0 12.276 1h-.552c-.825 0-1.516.622-1.605 1.44l-.197 1.823A7.94 7.94 0 0 0 8 5.06l-1.428-1.15a1.614 1.614 0 0 0-2.154.116l-.39.39a1.614 1.614 0 0 0-.117 2.154l1.151 1.428a7.94 7.94 0 0 0-.798 1.923l-1.822.197c-.82.089-1.441.78-1.441 1.605v.552c0 .825.622 1.516 1.44 1.605l1.823.197zM12 7.844a4.156 4.156 0 1 1-.002 8.313A4.156 4.156 0 0 1 12 7.844z"
      />
    </Icon>
  );
}

Settings.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

Settings.defaultProps = {
  className: undefined,
  color: css.colorGrey900,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};

export default Settings;
