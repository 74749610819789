import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function ClashTie({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      {...{ className, isFlipX, isFlipY, viewBox: '0 0 24 24', size, ...props }}
    >
      <g
        stroke={isCssColor ? 'currentColor' : color}
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <path d="M3.5 8.5h17M3.5 15h17" />
      </g>
    </Icon>
  );
}

ClashTie.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

ClashTie.defaultProps = {
  className: undefined,
  color: css.colorBlack,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 18,
};

export default ClashTie;
