import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function Info({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g
        stroke={isCssColor ? 'currentColor' : color}
        transform="translate(1 5)"
        strokeWidth=".75"
        fill="none"
        fillRule="evenodd"
      >
        <rect x=".375" y=".375" width="22" height="14" rx="1" />
        <path
          strokeLinecap="round"
          d="M.5 1.128l9.23 7.585a2 2 0 0 0 2.54 0l9.23-7.585M6.717 6.32l-3.2 2.957m11.899-3.001l3.256 3"
        />
      </g>
    </Icon>
  );
}

Info.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

Info.defaultProps = {
  className: undefined,
  color: css.colorBlack,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 18,
};

export default Info;
