import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function Prize({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g
        stroke={isCssColor ? 'currentColor' : color}
        strokeWidth=".75"
        fill="none"
        fillRule="evenodd"
        transform="translate(3.342 1.458)"
      >
        <path d="M13.216 15.852c.506 1.173 1.169 1.859 2.422 2.98l-2.466.699c-.804 1.156-1.219 1.773-1.243 1.85-.038.114-2.486-1.933-2.626-4.077m-5.186-1.43c-.522 1.06-1.352 2.1-2.49 3.118l2.466.7c.804 1.156 1.218 1.773 1.243 1.85.038.114 2.551-2.165 2.69-4.309" />
        <circle cx="8.717" cy="8.717" r="8.717" />
        <circle cx="8.717" cy="8.717" r="6.076" />
      </g>
    </Icon>
  );
}

Prize.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

Prize.defaultProps = {
  className: undefined,
  color: css.colorGrey900,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};

export default Prize;
