import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function Public({ className, color, size, ...props }) {
  return (
    <Icon className={className} viewBox="0 0 24 24" size={size} {...props}>
      <g stroke={color} strokeWidth="1" fill="none" fillRule="evenodd">
        <path d="M12 2C6.5 2 2 6.5 2 12s4.5 10 10 10 10-4.5 10-10S17.5 2 12 2z" />
        <path d="M18.634 4.526v.071c-.225.18-.412.397-.561.613-.113.18-.225.324-.337.432-.225.18-.523.288-.897.36-.337.109-.711.18-1.049.397-.561.397-.898 1.118-.861 1.91.038.684.336 1.298.6 1.839.074.18.149.323.149.432 0 .143-.075.324-.262.54a3.326 3.326 0 0 1-1.91 1.118c-.336.072-.562.072-.787 0-.187-.072-.374-.18-.636-.397a4.704 4.704 0 0 1-1.197-1.73.487.487 0 0 1-.075-.217 9.391 9.391 0 0 0-.3-.72c-.71-1.19-2.246-1.37-3.144-1.37-.561 0-1.16 0-1.348-.36-.075-.144-.075-.36-.075-.613 0-.288 0-.649-.187-.937-.225-.432-.674-.612-1.123-.72M5.65 19.404c.421-.658.589-1.387.421-2.115 0-.07-.043-.174-.043-.243-.084-.277-.127-.52-.043-.693.084-.173.295-.312.549-.485.337-.208.759-.45 1.096-.416.168.036.337.105.505.209.127.069.254.173.379.242.675.416 1.348.832 2.234.902.505.035.97-.035 1.391-.104.421-.07.8-.138 1.18-.104.254.035.59.138.675.346.043.105.043.278.043.416v1.11c0 .762.084 1.524.8 1.975.379.242.886.312 1.307.382.211.035.379.069.506.104M18.064 19.956c.585-1 .635-2.938.341-3.976a8.221 8.221 0 0 1-.146-.5c-.147-.424-.294-.809-.195-1.193.098-.5.585-.923.976-1.154.293-.192.682-.385 1.024-.577.244-.115.488-.231.732-.384.39-.193.828-.5 1.122-.923.05-.039.05-.076.05-.116v.385" />
      </g>
    </Icon>
  );
}

Public.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
};

Public.defaultProps = {
  className: undefined,
  color: css.colorBlack,
  size: 18,
};

export default Public;
