import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function Plus({ className, color, size, ...props }) {
  return (
    <Icon className={className} viewBox="0 0 24 24" size={size} {...props}>
      <g
        id="Library"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
        strokeLinecap="round"
      >
        <g id="library-/icon-/add-/default" stroke="#000000">
          <path d="M22,12.5 L2,12.5 L22,12.5 Z" id="Line-2" />
          <path d="M12.5,2 L12.5,22 L12.5,2 Z" id="Line-2" />
        </g>
      </g>
    </Icon>
  );
}

Plus.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  size: PropTypes.number,
};

Plus.defaultProps = {
  className: undefined,
  color: css.colorBlack,
  size: 18,
};

export default Plus;
