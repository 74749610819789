import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function FaqDuel({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 33 25"
      size={size}
      fill="none"
      {...props}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <path
          fill="#AC87F8"
          d="M.716 4.403l2.157-.405-.06-1.836C6.17 1.07 8.447.589 9.64.719c1.194.129 2.533.947 4.016 2.454 2.785-.849 4.744-1.24 5.876-1.171 1.131.068 2.465.537 4 1.407v14.993l-1.253-.667-.253 1.512-8.385-1.015-11.41.815L.716 4.403z"
          transform="translate(-333 -524) translate(331 518) rotate(-9 30.758 -2.166) translate(0 3.25) translate(.73)"
        />
        <path
          fill="#D8C9FC"
          d="M2.813 2.162C6.17 1.07 8.447.589 9.64.719c1.194.129 2.533.947 4.016 2.454 2.785-.849 4.744-1.24 5.876-1.171 1.131.068 2.465.537 4 1.407v14.993c-1.253-.939-2.586-1.42-4-1.445-1.415-.024-3.378.4-5.89 1.275-1.283-.75-2.54-1.175-3.772-1.275-1.232-.1-3.342.054-6.331.46L2.813 2.161z"
          transform="translate(-333 -524) translate(331 518) rotate(-9 30.758 -2.166) translate(0 3.25) translate(.73)"
        />
        <path
          fill="#EAE1FF"
          d="M13.657 3.173c2.785-.849 4.744-1.24 5.876-1.171 1.131.068 2.465.537 4 1.407v14.993c-1.253-.939-2.586-1.42-4-1.445-1.415-.024-3.378.4-5.89 1.275l.014-15.06z"
          transform="translate(-333 -524) translate(331 518) rotate(-9 30.758 -2.166) translate(0 3.25) translate(.73)"
        />
        <g
          stroke="#000"
          strokeWidth="0.75"
          transform="translate(-333 -524) translate(331 518) rotate(-9 30.758 -2.166) translate(0 3.25) translate(1.24 .51)"
        >
          <path d="M13.171 17.371L13.133 2.7s6.23-2.86 9.841.185l.049 15.243s-4.197-3.029-9.852-.757zM2.364 3.488l-2.157.405 1.515 14.645 11.41-.815c-3.424-2.646-10.101-.816-10.101-.816L2.303 1.652s7.42-3.86 10.83 1.048" />
          <path d="M13.1480672 17.7225353L21.5655044 18.7368028 21.6999981 17.4737311" />
        </g>
        <g>
          <g fillRule="nonzero">
            <path
              fill="#A2F76B"
              d="M10.844 10.233c.358-.76.451-1.55-.357-1.856-.808-.306-1.225.66-1.484 1.1l-2.827 5.214c-.307.514-.267 1.086.29 1.374.447.231 1.154.127 1.561-.555.272-.455 1.21-2.214 2.817-5.277z"
              transform="translate(-333 -524) translate(331 518) translate(17.591 13.591) translate(-3.312 -2.422) translate(.267) rotate(11 1.756 21.832) rotate(-4 22.942 -1.988)"
            />
            <path
              fill="#EFEFEF"
              d="M14.286 4.502c1.18-2.138 2.008-2.09 2.008-2.09l-.788-.472.61-.727c-1.22-.472-2.594-.033-3.346 1.07-.626.787-2.516 4.52-2.516 4.52a1.27 1.27 0 00.005 1.232c.213.38.606.614 1.031.613a1.182 1.182 0 001.027-.62l1.969-3.526z"
              transform="translate(-333 -524) translate(331 518) translate(17.591 13.591) translate(-3.312 -2.422) translate(.267) rotate(11 1.756 21.832) rotate(-4 22.942 -1.988)"
            />
            <path
              fill="#EFEFEF"
              d="M5.076 4.148c3.137 1.789 4.443 2.787 2.37 5.058C6.027 10.759 4.456 10.75 2.322 7.6.787 5.28.25 2.761.424 2.578c.06-.056 2.326.26 4.652 1.57z"
              transform="translate(-333 -524) translate(331 518) translate(17.591 13.591) translate(-3.312 -2.422) translate(.267) rotate(11 1.756 21.832) rotate(-4 22.942 -1.988) rotate(2 4.421 6.396)"
            />
            <path
              fill="#D7FFBD"
              d="M12.935 9.739c.463-.787.185-1.5-.62-1.624-.804-.124-1.41.998-1.53 1.169l-2.84 4.336c-.396.53-.853 1.563.09 2.11.563.213.895.152 1.42-.553.351-.47 1.511-2.283 3.48-5.438z"
              transform="translate(-333 -524) translate(331 518) translate(17.591 13.591) translate(-3.312 -2.422) translate(.267) rotate(11 1.756 21.832) rotate(-4 22.942 -1.988) rotate(-82 10.319 11.972)"
            />
            <path
              stroke="#000"
              strokeWidth="0.75"
              d="M8.078 11.583l-1.804 3.334c-.21.35-.077.789.312.99.355.184.849.04 1.134-.439.186-.312.69-1.246 1.507-2.792l-1.149-1.093zm2.395-1.278l.168-.32c.367-.784.322-1.247-.166-1.432-.417-.158-.8.159-1.193.81l1.19.942zM15.864 2l-.596-.345.572-.654a2.803 2.803 0 00-2.707 1.159c-.229.276-.666.986-1.236 1.99l-.15.264a107.642 107.642 0 00-1.342 2.466.92.92 0 00.003.924.932.932 0 001.614-.007l2.163-3.722v.002C14.8 3.004 15.37 2.347 15.863 2z"
              transform="translate(-333 -524) translate(331 518) translate(17.591 13.591) translate(-3.312 -2.422) translate(.267) rotate(11 1.756 21.832) rotate(-4 22.942 -1.988)"
            />
          </g>
          <g stroke="#000" strokeWidth="0.75">
            <g fillRule="nonzero">
              <path
                d="M5.224 2.628a13.7 13.7 0 00-3.09-1.262c-.378-.103-.729-.18-1.011-.227l.01.066c.05.3.15.692.288 1.12a13.456 13.456 0 001.545 3.22C4.83 8.292 6.132 8.606 7.501 7.105c.915-1.001 1.093-1.656.726-2.262-.36-.592-1.159-1.165-3.003-2.216zM.884 1.106a1.743 1.743 0 00-.036-.003c.033.002.05.004.063.002z"
                transform="translate(-333 -524) translate(331 518) translate(17.591 13.591) translate(-3.312 -2.422) rotate(6 -25.046 30.17) rotate(1 -33.563 48.788) rotate(2 4.754 4.55)"
              />
              <path
                d="M13.201 7.705c.317-.544.074-1.082-.483-1.168-.23-.036-.54.15-.868.517-.1.11-.194.23-.286.358a13.27 13.27 0 00-.156.225l-2.965 4.485c-.438.585-.468 1.277.034 1.575.49.185.79.105 1.166-.4.341-.457 1.534-2.332 3.558-5.592z"
                transform="translate(-333 -524) translate(331 518) translate(17.591 13.591) translate(-3.312 -2.422) rotate(6 -25.046 30.17) rotate(1 -33.563 48.788) rotate(-82 10.729 10.16)"
              />
            </g>
            <path
              d="M12.387 12.976l1.117-1.224"
              transform="translate(-333 -524) translate(331 518) translate(17.591 13.591) translate(-3.312 -2.422) rotate(6 -25.046 30.17)"
            />
          </g>
        </g>
      </g>
    </Icon>
  );
}

FaqDuel.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

FaqDuel.defaultProps = {
  className: undefined,
  color: css.colorGrey900,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};

export default FaqDuel;
