import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function FaqSummon({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 34 31"
      size={size}
      fill="none"
      {...props}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.34418 12.1169L5.4111 11.3795L5.06403 9.57564C5.06403 9.57564 9.78333 7.17058 11.5829 7.08204C13.3824 6.99349 15.9334 8.87785 15.9334 8.87785C15.9334 8.87785 19.8602 6.96714 21.5531 6.80202C23.2459 6.63691 25.7238 7.56639 25.7238 7.56639L28.0692 22.3747L26.7276 21.9124L26.7147 23.4448L18.2742 23.7538L7.13178 26.3442L3.34418 12.1169Z"
        fill="#AC87F8"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.06405 9.57563C5.06405 9.57563 9.78336 7.17058 11.5829 7.08203C13.3824 6.99348 15.9335 8.87784 15.9335 8.87784C15.9335 8.87784 19.8603 6.96713 21.5531 6.80202C23.2459 6.6369 25.7238 7.56638 25.7238 7.56638L28.0692 22.3747C28.0692 22.3747 25.994 21.2775 23.8926 21.5734C21.7912 21.8693 18.2743 23.7538 18.2743 23.7538C18.2743 23.7538 16.198 22.9431 14.3498 23.0848C12.5015 23.2266 8.16883 24.5288 8.16883 24.5288L5.06405 9.57563Z"
        fill="#D8C9FC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M15.9334 8.87785C15.9334 8.87785 19.8602 6.96714 21.553 6.80202C23.2459 6.63691 25.7237 7.56639 25.7237 7.56639L28.0691 22.3747C28.0691 22.3747 25.994 21.2775 23.8926 21.5734C21.7912 21.8693 18.2742 23.7538 18.2742 23.7538L15.9334 8.87785Z"
        fill="#EAE1FF"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.2573 23.4008L15.9242 8.9162C15.9242 8.9162 21.6298 5.11655 25.6733 7.55964L28.1059 22.6067C28.1059 22.6067 23.4869 20.272 18.2573 23.4008Z"
        stroke="black"
        strokeWidth="0.75"
      />
      <path
        d="M5.41112 11.3795L3.3442 12.1169L7.1318 26.3442L18.2742 23.7538C14.4778 21.6762 8.16882 24.5288 8.16882 24.5288L5.06405 9.57564C5.06405 9.57564 11.7888 4.60147 15.9242 8.9162"
        stroke="black"
        strokeWidth="0.75"
      />
      <path
        d="M18.2892 23.7514L26.7617 23.4365L26.6969 22.1679"
        stroke="black"
        strokeWidth="0.75"
      />
      <path
        d="M32.279 22.624C31.3686 25.4878 29.1186 27.3623 25.6878 27.3623C22.668 27.3623 20.7093 25.3224 19.5374 22.624C19.5374 22.624 18.1413 18.4604 20.5768 17.6279C20.6145 17.615 23.7665 17.3298 25.9744 17.3298C28.1824 17.3298 30.0143 17.0186 31.7025 17.8347C33.3908 18.6509 32.0151 22.624 32.279 22.624Z"
        fill="#FFE9F1"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M30.9506 24.8172C31.0322 24.9481 31.1954 25.2099 31.1954 25.2099C31.3336 25.448 31.5642 25.6181 31.8325 25.6798C32.1007 25.7415 32.3826 25.6892 32.6109 25.5355C33.0916 25.1821 33.2222 24.5197 32.9117 24.0103C32.9117 24.0103 32.4238 22.8183 32.1799 22.2223"
        fill="#FFC6DB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.7645 22.7906C19.5163 23.3488 19.0197 24.4653 19.0197 24.4653C18.7662 25.0037 18.8719 25.5934 19.3891 25.8878C19.9062 26.1822 20.606 25.903 20.8634 25.442C20.8634 25.442 21.0543 25.1521 21.1498 25.0071"
        fill="#FFC6DB"
      />
      <path
        d="M22.1634 23.9564L21.1018 27.4952C20.8726 28.179 21.2131 28.9235 21.8803 29.1973C22.2016 29.3072 22.554 29.28 22.8547 29.122C23.1553 28.964 23.3775 28.6892 23.4692 28.3622L24.9767 25.3082"
        fill="#FFE9F1"
      />
      <path
        d="M27.1335 24.6395L28.2659 28.1429C28.354 28.4715 28.5734 28.7494 28.8726 28.9114C29.1718 29.0734 29.5244 29.1051 29.8478 28.9993C30.5179 28.7338 30.8664 27.9933 30.644 27.3077L30.1202 23.8964"
        fill="#FFE9F1"
      />
      <path
        d="M30.9506 24.8172C31.0322 24.9481 31.1954 25.2099 31.1954 25.2099C31.3336 25.448 31.5642 25.6181 31.8325 25.6798C32.1007 25.7415 32.3826 25.6892 32.6109 25.5355C33.0916 25.1821 33.2222 24.5197 32.9117 24.0103C32.9117 24.0103 32.4238 22.8183 32.1799 22.2223"
        stroke="black"
        strokeWidth="0.75"
      />
      <path
        d="M19.7645 22.7906C19.5163 23.3488 19.0197 24.4653 19.0197 24.4653C18.7662 25.0037 18.8719 25.5934 19.3891 25.8878C19.9062 26.1822 20.606 25.903 20.8634 25.442C20.8634 25.442 21.0543 25.1521 21.1498 25.0071"
        stroke="black"
        strokeWidth="0.75"
      />
      <path
        d="M22.1634 23.9564L21.1018 27.4952C20.8726 28.179 21.2131 28.9235 21.8803 29.1973C22.2016 29.3072 22.554 29.28 22.8547 29.122C23.1553 28.964 23.3775 28.6892 23.4692 28.3622L24.9767 25.3082"
        stroke="black"
        strokeWidth="0.75"
      />
      <path
        d="M27.3022 25.1513L28.266 28.1429C28.3541 28.4715 28.5735 28.7494 28.8726 28.9114C29.1718 29.0734 29.5245 29.1051 29.8478 28.9993C30.5179 28.7338 30.8665 27.9933 30.644 27.3077L30.1203 23.8964"
        stroke="black"
        strokeWidth="0.75"
      />
      <path
        d="M22.6538 28.5322C22.7108 28.3929 22.5415 28.1918 22.2756 28.083C22.0096 27.9743 21.7479 27.999 21.6909 28.1383C21.6339 28.2775 21.8033 28.4786 22.0692 28.5874C22.3351 28.6962 22.5969 28.6715 22.6538 28.5322Z"
        fill="black"
      />
      <path
        d="M29.5861 28.2364C29.852 28.1276 30.0214 27.9265 29.9644 27.7872C29.9074 27.6479 29.6457 27.6232 29.3797 27.732C29.1138 27.8408 28.9445 28.0419 29.0015 28.1812C29.0584 28.3204 29.3202 28.3452 29.5861 28.2364Z"
        fill="black"
      />
      <path
        d="M24.2208 27.0836C24.9767 27.5003 27.456 27.4114 27.8848 27.0836"
        stroke="black"
        strokeWidth="0.75"
      />
      <path
        d="M21.7148 25.4097C20.2682 24.2868 17.9237 20.2425 20.2682 17.9654C21.5647 16.7062 24.2208 17.4111 25.8926 17.4377C27.5643 17.4642 30.711 16.4461 32.0152 18.1518C33.5645 20.1781 31.2023 24.8009 30.3534 25.4097"
        stroke="black"
        strokeWidth="0.75"
      />
    </Icon>
  );
}

FaqSummon.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

FaqSummon.defaultProps = {
  className: undefined,
  color: css.colorGrey900,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};

export default FaqSummon;
