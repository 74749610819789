import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';

function PrizeColor({ className, isFlipX, isFlipY, size, ...props }) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g stroke="#000" fill="none" fillRule="evenodd">
        <g transform="translate(3.342 1.458)" strokeWidth=".75">
          <path
            d="M13.216 15.852c.506 1.173 1.169 1.859 2.422 2.98l-2.466.699c-.804 1.156-1.219 1.773-1.243 1.85-.038.114-2.486-1.933-2.626-4.077m-5.186-1.43c-.522 1.06-1.352 2.1-2.49 3.118l2.466.7c.804 1.156 1.218 1.773 1.243 1.85.038.114 2.551-2.165 2.69-4.309"
            fill="#FFA342"
          />
          <circle fill="#FFE877" cx="8.717" cy="8.717" r="8.717" />
          <circle fill="#FFD45A" cx="8.717" cy="8.717" r="6.076" />
        </g>
        <path
          d="M18.572 7.75v.203l-.024.024-.023-.024V7.75h.047zm-.023-1.61a2.626 2.626 0 0 1 2.246-2.242 2.626 2.626 0 0 1-2.247-2.25 2.626 2.626 0 0 1-2.246 2.241 2.626 2.626 0 0 1 2.247 2.25zm3.867-2.266h.202l.024.024-.024.025h-.202v-.049zM18.525.25V.047l.023-.024.024.024V.25h-.047zm-3.61 3.663h-.2l-.025-.024.024-.024h.202v.048z"
          strokeWidth=".5"
          fill="#FFF"
        />
      </g>
    </Icon>
  );
}

PrizeColor.propTypes = {
  className: PropTypes.string,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

PrizeColor.defaultProps = {
  className: undefined,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};

export default PrizeColor;
