import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function Battle({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g
        strokeWidth=".75"
        stroke={isCssColor ? 'currentColor' : color}
        fill="none"
        fillRule="evenodd"
      >
        <path
          d="M10.202 15.467l-3.021 4.247c-.379.488-.261 1.172.28 1.542.495.338 1.246.216 1.742-.424.303-.39 1.14-1.572 2.505-3.534l-1.506-1.831zm3.4-.902l.364-.526c.647-1.03.664-1.73-.034-2.098-.62-.327-1.24.091-1.933.998h0l1.603 1.626zM19.492 7c1.072-1.427 1.98-2.203 2.695-2.53l-.772-.58.915-.82a3.854 3.854 0 0 0-4.104 1.057c-.355.335-1.051 1.207-1.973 2.45-.08.106-.16.215-.241.325a143.644 143.644 0 0 0-1.987 2.773l-.19.272a1.352 1.352 0 0 0-.16 1.348 1.368 1.368 0 0 0 2.35.278L19.493 7zM7.31 5.683a18.391 18.391 0 0 0-3.832-2.322 13.956 13.956 0 0 0-1.3-.516 6.33 6.33 0 0 0-.244-.076c-.073.687.515 4.044 1.604 6.435C5.448 13.277 7.187 14 9.375 12.25c1.452-1.159 1.848-2.037 1.465-2.973-.36-.882-1.301-1.81-3.53-3.595zm3.68 6.808c-.653-.66-1.537-.514-1.876.255-.148.335-.003.834.376 1.416.111.17.235.337.37.502.077.094.257.303.24.282l4.748 5.361c.642.817 1.604 1.12 2.24.495.438-.61.44-1.086-.116-1.79-.475-.6-2.477-2.784-5.983-6.521z"
          fillRule="nonzero"
        />
        <path d="M14.238 19.448l1.651-1.466" />
      </g>
    </Icon>
  );
}

Battle.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

Battle.defaultProps = {
  className: undefined,
  color: css.colorGrey900,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};

export default Battle;
