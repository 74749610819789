import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function Notifications({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g
        stroke={isCssColor ? 'currentColor' : color}
        strokeWidth=".75"
        fill="none"
        fillRule="evenodd"
      >
        <path d="M2.981 18.433l1.47-2.302v-5.258a7.551 7.551 0 0 1 5.226-7.186v-.364A2.324 2.324 0 0 1 12 1a2.322 2.322 0 0 1 2.323 2.323v.364a7.553 7.553 0 0 1 5.227 7.186v5.258l1.469 2.302a1 1 0 0 1-.843 1.538H3.824a1 1 0 0 1-.843-1.538zM9.5 21a2.5 2.5 0 1 0 5 0" />
      </g>
    </Icon>
  );
}

Notifications.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

Notifications.defaultProps = {
  className: undefined,
  color: css.colorGrey900,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};

export default Notifications;
