import React from 'react';
import PropTypes from 'prop-types';
import Icon from './Icon';
import css from 'styles/config.colors.css';

function CheeseWheel({
  className,
  color,
  isCssColor,
  isFlipX,
  isFlipY,
  size,
  ...props
}) {
  return (
    <Icon
      className={className}
      isFlipX={isFlipX}
      isFlipY={isFlipY}
      viewBox="0 0 24 24"
      size={size}
      {...props}
    >
      <g fill="none" fillRule="evenodd">
        <path
          stroke={isCssColor ? 'currentColor' : color}
          strokeWidth=".75"
          d="M4.798 3.786A10.696 10.696 0 0 0 .74 12.363c0 5.708 4.119 10.332 9.2 10.332 5.081 0 9.206-4.624 9.206-10.332 0-4.899-3.037-9.006-7.114-10.065L9.467 14.892 4.798 3.786z"
        />
        <path
          stroke={isCssColor ? 'currentColor' : color}
          strokeWidth=".75"
          d="M4.906 3.584l3.22.109 2.31 6.025m1.391-7.503l6.056.557a10.914 10.914 0 0 1 4.697 8.96c.078 6.214-2.732 9.005-5.2 10.309 0 0-4.54.762-8.343.596"
        />
        <path
          fill={isCssColor ? 'currentColor' : color}
          fillRule="nonzero"
          d="M10.59 19.109s-.494.503-1.16.489c-.758-.017-1.579-.246-1.423-.584.155-.337.333-.195.834-.09.582.121.817.01 1.305-.262.487-.273.831.084.445.447zm5.62-8.949c-.107-.65-.758-.507-.98-.215-.22.291-.43 2.893-.107 3.178a.522.522 0 0 0 .909-.357c.154-.86.214-1.734.179-2.607zm-12.966.578s.472-.4.723.434c.251.833.257 2.732-.18 2.673-.436-.06-.471-.977-.543-1.786a6.057 6.057 0 0 1 0-1.321z"
        />
      </g>
    </Icon>
  );
}

CheeseWheel.propTypes = {
  className: PropTypes.string,
  color: PropTypes.string,
  isCssColor: PropTypes.bool,
  isFlipX: PropTypes.bool,
  isFlipY: PropTypes.bool,
  size: PropTypes.number,
};

CheeseWheel.defaultProps = {
  className: undefined,
  color: css.colorGrey900,
  isCssColor: false,
  isFlipX: false,
  isFlipY: false,
  size: 24,
};

export default CheeseWheel;
